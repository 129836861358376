import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { withAuth0 } from '@auth0/auth0-react';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { PDFDocument } from 'pdf-lib'
import QRCode from 'react-qr-code';
import '../styles/Lookup.css';
import SessionContext from './SessionContext.js';
import EquipmentDataGrid from './EquipmentDataGrid';

const calibrationColumns = [
    {field: 'job_num', headerName: 'Job Number', width: 200, editable: false},
    {field: 'inspection_date', headerName: 'Inpection Date', width: 200, editable: false},
    {field: 'technician_name', headerName: 'Technician', width: 200, editable: false},
    {field: "Void", width: 150, renderCell: (cellValues) => { return (<Button variant="custom-red" disabled={!('void' in cellValues.row)} style={('void' in cellValues.row) ? {} : {opacity: '75%'}} onClick={(event) => {} } > Void </Button>);}},
    {field: "Download Previous", width: 200, renderCell: (cellValues) => { return (<Button variant="custom-red" disabled={!('previous_versions' in cellValues.row) || cellValues.row?.previous_versions?.length === 0} style={!('previous_versions' in cellValues.row) || cellValues.row?.previous_versions?.length === 0 ? {opacity: '75%'} : {} } onClick={(event) => {}}> Download Previous </Button>);}},
];                                                                                                                                      

class LookupAll extends React.Component {
    static contextType = SessionContext;

    constructor() {
        super();
        this.state = {
            equipment: null,
            equipments: [],
            calibrations: [],
            firstLoad: true
        };
    }

    /**
     *  Runs at page load, after the component is mounted. Gets auth token, user and list of companies. If the user can only see one company, just selects that company for them.
     */
    async componentDidMount() {
        this.setState({rerender: true});
    }

    async componentDidUpdate() {

        if (this.context.companies && this.state.firstLoad && this.props.match?.params?.company_id && (this.props.match?.params.company_id !== this.context.company?.id)) { // if a company is provided in the url--and isn't already the selected company
            this.context.updateContext('CHANGE_COMPANY', this.context.companies.filter(e => e.id === this.props.match.params.company_id)[0]) // update the session with the desired company
        } else if(this.context.companies && !this.context?.company && this.state.firstLoad && this.context.companies.length === 1) {
            await this.context.updateContext('CHANGE_COMPANY', this.context.companies[0])
        }

        if (this.context.company && this.context.company.id !== this.state.prevCompanyId) { // if context has a company and its ID is not the same as the previously ID, then it's time to update
            this.setState({prevCompanyId: this.context.company.id}, () => { // set the new previous ID so we don't infinitely update
                    this.selectCompany(this.context.company);
            })
        }
    }

    /**
     * Handler for the company dropdown list. Fetches a list of equipment for the selected company, and uses it to populate the equipment table.
     * @param {*} company the company that was selected in the dropdown list
     */
    selectCompany = async company => {
        this.setState({ equipments: [{id: 'Loading...', name: 'Loading...'}], equipment: null, calibrations: []}, async () => { // update the selected option in state
            this.setState({equipments : await (await fetch(process.env.REACT_APP_API_URL + company.id + '/equipment', {headers: { Authorization: 'Bearer ' + this.context.authToken }, method: 'GET'})).json()}, async () => {
                if (this.props.match.params.equipment_id && this.state.firstLoad) { // if an equipment id is provided in the url, AND the page is loading for the first time,
                    this.setState({firstLoad: false}, async () => { // unflag the first page load
                        this.selectEquipment(this.state.equipments.filter(element => element.id === this.props.match.params.equipment_id)[0]) // and select the indicated piece of equipment for the user.
                    })
                }
            }); // get the list of equipment based on the selected option.
        });
    };

    selectEquipment = async row => {
        if (row === undefined || row.id === 'Loading...') { return }
        this.setState({equipment: null}, async () => { // clear out the equipment so the fields will disappear
            this.setState({equipment: (await (await fetch(process.env.REACT_APP_API_URL + this.context.company.id + '/equipment/' + row.id, { headers: { Authorization: this.context.authToken }})).json())[0]}, async () => { // retrieve the selected equipment document
                this.setState({calibrations: [{id: 'Loading...', job_num: 'Loading...', inspection_date: 'Loading...', technician_name: 'Loading...'}]}, async () => {
                    if (this.state.equipment) {
                        let calibrations = await Promise.all(this.state.equipment.calibrations.map(async (id) => { // we await a promise.all here since array.map() seems to return an array of promises
                            return (await (await fetch(process.env.REACT_APP_API_URL + this.context.company.id + '/calibrations/' + id, {headers: { Authorization: this.context.authToken }})).json())[0] // use each calibration id to fetch the respective calibration document']
                        }))
                        this.setState({calibrations: calibrations})
                    } else {
                        this.setState({calibrations: []})
                    }
                })
            })
        })
    }

    rowClickHandler = async row => {
        if(row.field !== 'job_num' && row.field !== 'technician_name' && row.field !== 'inspection_date') { 
            if(row.field === 'Void') { // Void button
                row.row.void = true;
                let put_response = await fetch(process.env.REACT_APP_API_URL + this.context.company.id + '/calibrations/' + row.row.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(row.row)}) // update the calibration document to void = true
                if (put_response.status === 200) {
                    console.log("SUCCESS")
                    let cal_document = await put_response.json();
                    localStorage.setItem('old_cal_values', JSON.stringify(cal_document)); // add the old calibration document to localStorage for use on add-calibrations page
                    window.location.replace('https://calibrations.iss-digital.com/add-calibrations/' + this.context.company.id + '/' + this.state.equipment.id) // this line reroutes you to the add-calibrations page 
                } else {
                    console.log("FAILURE")
                    alert("Unable to void calibration at this time. Please refresh and try again!\nIf problem persists please submit a support ticket.");
                }
                
            } else { // Download Previous button
                var cals = [];
                if('previous_versions' in row.row) {
                    for(let i = 0; i < row.row.previous_versions.length; i++) {
                        cals = cals.concat(this.state.calibrations.filter((cal) => {return (cal.id === row.row.previous_versions[i] && 'blob_id' in cal)}));
                    }
                }
                const newPDF = await PDFDocument.create() // create a blank PDF
                for (let index = 0; index < cals.length; index++) { // iterate for each calibration in 'cals'
                    var cal = cals[index];
                    // temp_blob = API call to fetch blob with id(cal.blob_id) in an ArrayBuffer
                    this.setState({temp_blob: await (await fetch(process.env.REACT_APP_API_URL + this.context.company.id + '/blobs/' + cal.blob_id + '/download', { headers: { Authorization: this.context.authToken }})).arrayBuffer()}, async () => {
                        // then load fetched PDF(temp_blob) to temp_doc
                        this.setState({temp_doc: await PDFDocument.load(this.state.temp_blob)}, async () => {
                            // then copy the first page from temp_doc to donorPage
                            this.setState({donorPage: await newPDF.copyPages(this.state.temp_doc, [0])}, async () => {
                                // then add donorPage to newPDF
                                newPDF.addPage(this.state.donorPage[0]);
                                if(index === cals.length - 1){ // if there are no more PDFs
                                    var pdfBytes = await newPDF.save() // save newPDF to pdfbytes (a Uint8Array)
                                    var blobBuilder = new Blob([pdfBytes.buffer], { type: 'application/pdf'}) // create a new blob with pdfBytes and type PDF
                                    window.open(URL.createObjectURL(blobBuilder), '_blank'); // open final PDF in new tab
                                }
                            });
                        });
                    });
                }
            }
        } else {
            if (row === undefined || row.id === 'Loading...') { return }
                let calibration = this.state.calibrations.filter(c => c.id === row.id) // returns a single-length array for which the element is the calibration whose id matches uuid
            if (calibration) {
                let blob = await (await fetch(process.env.REACT_APP_API_URL + this.context.company.id + '/blobs/' + calibration[0].blob_id + '/download', { headers: { Authorization: this.context.authToken }})).blob() // Gets the response and returns it as a blob
                window.open(URL.createObjectURL(blob), '_blank');
            } else {
                console.log("rowClickHandler: couldn't select calibration!")
            }        
        }
            
    }

    downloadQRCode() {
        const svg = document.getElementById("qrCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }

    render() {
        return (
            <>
                <br /><br /><br /><br />
                <h2 className="top-header">Lookup Equipment and Calibrations</h2><br />
                <div className="data-grid" style={{ height: '27.5em', width: '80%', marginLeft: '10%' }}>
                <EquipmentDataGrid equipments={this.state.equipments} onRowClick={(s) => {this.selectEquipment(s)}} />
                </div>
                <div id="equipmentInfo" style={{visibility: this.state.equipment === null ? 'hidden' : 'visible'}}>
                    <div id="company_contact">
                        <h2>{this.state.equipment?.name}</h2>
                        <h4>Plant Contact: </h4>
                        <h4>Phone Number: </h4>
                        <h4>Email Address: </h4>
                    </div>
                    <div id="qr_code">
                        <h5>QR Code:</h5>
                        <div>
                            <QRCode id="qrCode" value={'https://calibrations.iss-digital.com/calibrations/' + this.context.company?.id + '/' + this.state.equipment?.id} onClick={this.downloadQRCode} size={128}/><br/>
                            <input id="generateQRCode" type="button" value="Download" onClick={this.downloadQRCode}/>
                        </div>
                    </div>
                    <div id="manufacturer"><h5>Manufacturer: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.manufacturer}</h3></div>
                    <div id="model_number">
                        {this.state.equipment?.model_number !== '' ? <><h5>Model Number: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.model_number}</h3></> : ''}
                        {this.state.equipment?.actuator_model_number !== '' ? <h5>Actuator Model Number: {this.state.equipment?.actuator_model_number}</h5> : ''}
                        {this.state.equipment?.positioner_model_number !== '' ? <h5>Positioner Model Number: {this.state.equipment?.positioner_model_number}</h5> : ''}
                        {this.state.equipment?.valve_body_model_number !== '' ? <h5>Valve Body Model Number: {this.state.equipment?.valve_body_model_number}</h5> : ''}
                    </div>
                    <div id="serial_number"><h5>Serial Number: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.serial_number}</h3></div>
                    <div id="tag_number">
                        <h5>Tag Number:</h5><h3 className='equipmentCellContent'>{this.state.equipment?.tag_number}</h3>
                        <h5>Loop Number:</h5><h3 className='equipmentCellContent'>{this.state.equipment?.loop_number}</h3>
                    </div>
                    <div id="location">
                        <h5>Company Location: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.location}</h3>
                        <h5>Site Location: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.site_location}</h3>
                    </div>
                    <div id="area"><h5>Area: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.area}</h3></div>
                    <div id="description"><h5>Description: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.description}</h3></div>
                    <div id="control"><h5>Control: </h5><input className='typeCheckboxEquipment' type='checkbox' disabled={true} checked={this.state.equipment?.type_descriptor?.includes('control')}></input></div>
                    <div id="interlock"><h5>Interlock: </h5><input className='typeCheckboxEquipment' type='checkbox' disabled={true} checked={this.state.equipment?.type_descriptor?.includes('interlock')}></input></div>
                    <div id="alarm"><h5>Alarm: </h5><input className='typeCheckboxEquipment' type='checkbox' disabled={true} checked={this.state.equipment?.type_descriptor?.includes('alarm')}></input></div>
                    <div id="ind-rec"><h5>Ind/Rec: </h5><input className='typeCheckboxEquipment' type='checkbox' disabled={true} checked={this.state.equipment?.type_descriptor?.includes('ind-rec')}></input></div>
                    <div id="insp_freq"><h5>Inspection Frequency: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.inspection_frequency}</h3></div>
                    <div id="insp_last"><h5>Last Inspection Date:</h5><h3 className='equipmentCellContent'>{'N/A'}</h3></div>
                    <div id="test_freq"><h5>Testing Frequency: </h5><h3 className='equipmentCellContent'>{this.state.equipment?.testing_frequency}</h3></div>
                    <div id="test_last"><h5>Last Testing Date:</h5><h3 className='equipmentCellContent'>{'N/A'}</h3></div>
                </div>
                {this.context.user?.spectare ? <NavLink to={'/add-calibrations/' + this.context.company?.id + '/' + this.state.equipment?.id}><button id='add-cal-button' disabled={this.state.equipment === null} style={{visibility: this.state.equipment === null ? 'hidden' : 'visible'}}>Add Calibration</button></NavLink> : <></>}
                <div style={{position: 'relative'}}>
                    <div className="data-grid" id="calibration-table" style={{ height: 450, width: '80%', marginLeft: '10%', visibility: this.state.equipment === null ? 'hidden' : 'visible' }}>
                    <DataGrid
                        id="data-grid"
                        rows={this.state.calibrations.filter((element) => {return (element?.void !== true)})}
                        columns={this.context?.user?.spectare && this.context?.appIsOnline ? calibrationColumns : calibrationColumns.slice(0,3)}
                        pageSize={6}
                        rowsPerPageOptions={[6]}
                        disableMultipleSelection={true}
                        onCellClick={(newSelection) => {this.rowClickHandler(newSelection)}}
                    />
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(withAuth0(LookupAll));