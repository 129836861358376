import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

// This component simply extends the Auth0Provider with the useHistory hook from React Router.
// Note the history.push(appState?.returnTo)--Auth0 lets us send an optional param with our auth request, and we get it back here.
// History is React Router-specific, and switches to that URL without reloading the page (which makes us sign in again!)

export default function Auth0ProviderWithHistory({children}) {
  const history = useHistory();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={(appState) => {
        history.push(appState?.returnTo || window.location.pathname) // use original URL if available
      }}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPES}
    >
      {children}
    </Auth0Provider>
  );
};