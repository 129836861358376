import React from 'react';
import '../styles/Calibrations.css';
import { withAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import SessionContext from './SessionContext.js';
import { Button } from 'react-bootstrap';

const api_url = process.env.REACT_APP_API_URL

class AddPOC extends React.Component {
    static contextType = SessionContext;

    constructor(props) {
        super(props);
        this.state =  {first_name: '', last_name: '', phone_number: '', email: '', company: '', message: ''}; // set the current form to blank
        this.handle = this.handle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidUpdate() {
        if(this.context.companies && !this.context?.company && !this.state.firstLoad && this.context.companies.length === 1) {
            await this.context.updateContext('CHANGE_COMPANY', this.context.companies[0])
            this.setState({firstLoad: true})
        }
    }

    handle(event) { this.setState({[event.target.name]: event.target.value}); }

    //check the required fields to ensure they're filled out.
    checkRequired(event) {
        var alertNeeded = false;
        var alertOutput = "Please fill out all the required fields. Currently the following required fields are empty:";
        var pocRequired = ['first_name', 'last_name']; // required fields to be iterated over
        
        if(this.context?.company === undefined) { alertOutput += ('\nSelect company in the navbar'); alertNeeded = true;} // make sure that there is a comapny set in context

        // TODO: Change this over to a confirmation so they dont have to insert one or the other
        if(this.state.email === '' && this.state.phone_number === '') { // check to see if either a phone number or email is provided for the contact
            alertOutput += ('\nMust have either a phone number or an email'); 
            alertNeeded = true;
            document.getElementById('email').style.border = '2px solid red';
            document.getElementById('phone_number').style.border = '2px solid red';
        } else {
            document.getElementById('email').style.border = 'none';
            document.getElementById('phone_number').style.border = 'none';
        }

        //loop through remaining required fields. If the field is empty, surround it in red border and add to alert output. 
        for(var i = 0; i < pocRequired.length; i++) {
            if(this.state[pocRequired[i]] === '') {
                alertOutput += ('\n' + pocRequired[i])
                document.getElementById(pocRequired[i]).style.border = '2px solid red'; //highlight the missing field
                alertNeeded = true;
            } else {
                document.getElementById(pocRequired[i]).style.border = 'none'; // reset if there was any prvious missing field highlight
            }
        }

        if(alertNeeded) { alert(alertOutput); } // identify missing fields for the user
        return alertNeeded;
    }

    //run this function when the submit button is clicked.
    async handleSubmit(event) {
        //preventDefault stops the page from trying to POST at the website URL, which breaks the page.
        event.preventDefault();
        this.setState({runningHandleSubmit: true, message: ''});

        //run the code to POST data to the API if there were no empty required fields.
        if(!this.checkRequired(event)) {
            let param = {"first_name" : this.state.first_name, "last_name" : this.state.last_name, "phone_number": this.state.phone_number, "email": this.state.email, "company": this.state.company, "primary_location": this.context?.location ? this.context.location.name : "", "roles": ["default"]}

            //POST JSON data to API. If fetch is a success, set message to "Success!". Otherwise, set message to "Failure!" and output error message based on response information.
            var response = await fetch(api_url + this.context.company.id + '/pointsofcontact', {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'POST', body: JSON.stringify(param)})
            if (response.status === 200) {   
                this.setState({message : "Success!"}, async () => {});
            } else {
                this.setState({message : "Failure!"}, async () => {});
                let error = await response.json();
                alert("Error: " + error.status + "\nError message: " + error.message + "\nInvocation ID: " + error.invocation_id + "\nPlease include this error message when requesting support.");
            }
            
            document.getElementById('form1').reset(); //reset the form data
            this.setState({first_name: '', last_name: '', phone_number: '', email: '', company: ''}); //reset the neccessary state values
        } 

        this.setState({runningHandleSubmit: false}); //stop the spinning loading wheel and enable submit button again
    }

    render() {

        return (
            <>
            <br /><br /><br /><br />
            <div className="contact">
                <div className="contactSection" id="poc-page-container">
                    <form id="form1" onSubmit={this.handleSubmit}>
                        <h2 className="section-headers">Add Point Of Contact</h2><br/>

                        <label htmlFor="first_name">First Name: </label>
                        <input type="text" id="first_name" name="first_name" value={this.state.first_name} onChange={this.handle}/><br/>

                        <label htmlFor="last_name">Last Name: </label>
                        <input type="text" id="last_name" name="last_name" value={this.state.last_name} onChange={this.handle}/><br/>

                        <label htmlFor="phone_number">Phone Number: </label>
                        <input type="text" id="phone_number" name="phone_number" value={this.state.phone_number} onChange={this.handle}/><br/>

                        <label htmlFor="email">Email: </label>
                        <input type="text" id="email" name="email" value={this.state.email} onChange={this.handle}/><br/>

                        <label htmlFor="company">Contact Company: </label>
                        <input type="text" id="company" name="company" value={this.state.company} onChange={this.handle}/><br/>
                        
                        <label htmlFor="primary_location">Primary Location: </label>
                        <input type="text" id="primary_location" name="primary_location" style={{opacity: "75%"}}disabled={true} placeholder={this.context.location?.name ? this.context.location.name : ""}/><br/><br />

                        <Button variant="custom-red" id='form-buttons' type='submit' disabled={this.state.runningHandleSubmit || !this.context?.company || !this.context.appIsOnline} style={this.state.runningHandleSubmit || !this.context?.company || !this.context.appIsOnline ? {opacity: '75%'} : {opacity: '100%'}}>{this.state.runningHandleSubmit ? <CircularProgress size={24}/> : 'Submit'}</Button>
                        <p className={this.state.message === 'Success!' ? 'successMessage' : 'errorMessage'} style={this.state.message === '' ? {visibility: 'hidden'} : {visibility: 'visible'}}>{this.state.message}</p>
                    </form>
                </div>
            </div>
            </>
        );  
    }
}

export default withAuth0(AddPOC);