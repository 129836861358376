import '../styles/App.css';
import { useAuth0 } from '@auth0/auth0-react'
import ReportById from './ReportById';
import React from 'react';

// This component renders either a PBI report, a loading indicator or a login box. Right now, the PBI report seems to be broken, so it's only really useful for logging in.
// We should probably refactor the login to its own component anyway...

// TODO pretty up the loading box.

export default function Home() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  
  if (isAuthenticated) { 
    return (
      <>
        <div id="home-report"></div>
        <ReportById idVal={process.env.REACT_APP_HOME_REPORT_ID}/>
      </>
    )
  } else if (isLoading) {
    return (<div id="loginbox"><h2>Loading...</h2></div>)
  } else {
    return (
      <>
        <div id="loginbox">
          <h2>Log in to access your dashboards:</h2>
          <br />
          <button id="loginButton" onClick={() => {
              loginWithRedirect({appState: {returnTo: window.location.pathname}}) // pass the current path to Auth0 with state, so that we get it back after login!
          }}>Log In</button>
        </div>
      </>
    )
  }
}