import React from 'react'
import background from "../images/landing.mp4";
import backgroundWebm from "../images/landing.webm";

// A simple component to play a video on the landing page.

const LandingBackground = () => {
    
    return (
        <>
            <div className="container-video">
                <video autoPlay muted loop id="footer-vid">
                    <source src={background} type="video/mp4" />
                    <source src={backgroundWebm} type="video/webm" />
                </video>
            </div>
        </>
    )
}

export default LandingBackground;