import React, { Component } from "react";
import * as pbi from "powerbi-client";
import { withAuth0 } from '@auth0/auth0-react';

class ReportById extends Component {
    constructor() {
      super();
      this.state = { data: [] };
    }
  
    async componentDidMount() {

        // This section generates the access key for the api based on auth0
        const { getIdTokenClaims } = this.props.auth0;
        const claims = await getIdTokenClaims();
        // console.log(claims);

        // This section grabs the api endpoint and performs a fetch on it to get the embed details of the wanted powerbi report
        // const BASE_URI = 'http://localhost:1337'
        const BASE_URI = process.env.REACT_APP_API_ENDPOINT;
        // const reportId = process.env.REACT_APP_HOME_REPORT_ID; //set in the .env file that tells it what report is the home page
        const reportId = this.props.idVal;
        const bearerAccessToken = 'Bearer ' + claims.__raw; //token generated from the last section
        // console.log(bearerAccessToken);
        const response = await fetch(`${BASE_URI}/embedPBI/${reportId}`, {
            headers: {
            Authorization: bearerAccessToken,
            },
        });

        // Get the response from the api request and parse the response
        const reportDetails = await response.json();
        this.setState({ data: reportDetails });
        // console.log(reportDetails);

        // This section sets all the configuration for the powerbi model to be embeded in our webpage at the specified div
        var models = pbi.models;
        var permissions = models.Permissions.All;
        var config = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken:
            reportDetails.embedToken, // The three values with reportDetails should match the response from above
        embedUrl:
            reportDetails.embedUrl,
        id:
            reportDetails.reportId,
        permissions: permissions,
        settings: {
            panes: {
            filters: {
                visible: false,
            },
            pageNavigation: {
                visible: false,
            },
            },
        },
        };

        // Get a reference to the embedded report HTML element
        var embedContainer = document.getElementById("home-report");

        // Embed the report and display it within the div container.
        let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
        );
        var report = powerbi.embed(embedContainer, config);

        // Report.off removes a given event handler if it exists.
        report.off("loaded");

        // Report.on will add an event handler which prints to Log window.
        report.on("loaded", function () {
        // console.log("Loaded");
        });

        // Report.off removes a given event handler if it exists.
        report.off("rendered");

        // Report.on will add an event handler which prints to Log window.
        report.on("rendered", function () {
        // console.log("Rendered");
        });

        report.on("error", function (event) {
        /* eslint-disable no-console */
        console.log(event.detail);
        /* eslint-enable no-console */
        report.off("error");
        });

        report.off("saved");
        report.on("saved", function (event) {
        /* eslint-disable no-console */
        console.log(event.detail);
        if (event.detail.saveAs) {
            console.log(
            "In order to interact with the new report, create a new token and load the new report"
            );
        }
        /* eslint-enable no-console */
        });
    }
    
    // In order for it to run through our default app we must render some react frame so we render a empty one
    render() {
      return (
        <>
        </>
      );
    }
  }

export default withAuth0(ReportById);