import React from 'react';
import '../styles/Calibrations.css';
import { withAuth0 } from '@auth0/auth0-react';
import SessionContext from './SessionContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from '@material-ui/core';
import { Button } from 'react-bootstrap'
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogActions, Input } from '@material-ui/core';

const api_url = process.env.REACT_APP_API_URL
var costCodeID = 0;
var equipID = 0;

const costDataGridColumns = [
    {field: 'id', hide: true},
    {field: 'employee', headerName: 'Employee', flex: 1, editable: false},
    {field: 'hours', headerName: 'Hours', flex: .2, editable: false},
    {field: 'code_number', headerName: 'Code #', flex: .2, editable: false},
    {field: 'remove', headerName: 'Remove', flex: .2, renderCell: (cellValues) => { return (<Button variant="custom-red" > Remove </Button>);}}
]
const equipDataGridColumns = [
    {field: 'item_number', headerName: 'Item No.', flex: .2, editable: false},
    {field: 'quantity', headerName: 'QTY', flex: .2, editable: false},
    {field: 'equipment_description', headerName: 'Equipment Description', flex: 1, editable: false},
    {field: 'remove', headerName: 'Remove', flex: .2, renderCell: (cellValues) => { return (<Button variant="custom-red" > Remove </Button>);}}
]
class AddESR extends React.Component {
    static contextType = SessionContext
    constructor(props) {
        super(props);
        this.state = {jobs: [], results: '', user: '', auth_token: '', date: '', job_number: '', client: '', client_location: '', client_representative: '', client_number: '', client_email: '', other_email: '', cost_codes: [], employee: '', hours: '', code_number: '', mileage: '', work_description: '', equipment_information: [], item_number: '', quantity: '', equipment_description: '', testo: {'yes': false, 'description': ''}, calibration_full_gear: {'yes': false, 'description': ''}, manometer: {'yes': false, 'description': ''}, CAL_gas: {'value1': '', 'value2': '', 'yes': false, 'description': ''}, runningHandleSubmit: false, companiesLoaded: false, companyDropdown: [], companyDropdownString: 'Loading...', companyString: null}
        this.handle = this.handle.bind(this);
        this.handleJobNum = this.handleJobNum.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleTesto = this.handleTesto.bind(this);
        this.handleCalibrationFullGear = this.handleCalibrationFullGear.bind(this);
        this.handleManometer = this.handleManometer.bind(this);
        this.handleCALGas = this.handleCALGas.bind(this);
        this.handleCostCodes = this.handleCostCodes.bind(this);
        this.handleEquipmentInformation = this.handleEquipmentInformation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.jobCreationDialogConfirm = this.jobCreationDialogConfirm.bind(this);
        this.jobCreationDialogClose = this.jobCreationDialogClose.bind(this);
    }
    
    async componentDidUpdate() {
        if(this.context.company && this.context.company.id !== this.state.prevCompanyId) {
            this.setState({prevCompanyId: this.context.company.id, client: this.context.company.id}, async () => {
                this.fetchJobs()
            })
        }
    }

    async fetchJobs() {
        var job_response = await fetch(api_url + this.context?.company?.id + '/jobs', {headers: { Authorization: 'Bearer ' + this.context.authToken }, method: 'GET'})
        this.setState({jobs: await job_response.json()})
        console.log("jobs fetched")
    }

    handle(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    async handleJobNum(event) {
        if(event.target.id === "job_number" && (event.target.value.length === 5 || event.target.value.length === 7)) { // if the user enters a valid job number
            await this.fetchJobs();
            var extantJob = this.state.jobs.filter(j => j.job_num === event.target.value)
            this.setState({currentJobDocId: extantJob[0]?.id})
            console.log(extantJob[0].esrs)
            if(extantJob[0].esrs.length !== 0) {
                var text = "The job number you entered matches an existing job, would you like to prefill the form with data from " + event.target.value
                if(window.confirm(text) === true) { // if the job number exists, ask the user if they would like to prefill the form
                    this.prefillESR(extantJob[0]?.esrs[extantJob[0].esrs.length - 1])
                    console.log(extantJob[0]?.esrs[extantJob[0].esrs.length - 1])
                }
            }
            document.getElementById("job_number").style.border = 'none';
        } else {
            document.getElementById("job_number").style.border = '2px solid red';
        }
    }

    async prefillESR(esr_id) {
        var fetchESR = await fetch(api_url + this.context?.company?.id + '/esrs/' + esr_id, {headers: { Authorization: 'Bearer ' + this.context.authToken }, method: 'GET'})
        var selectedESR = await fetchESR.json()
        console.log(selectedESR[0].manometer)
        this.setState({'client_representative': selectedESR[0].client_representative,'client_number': selectedESR[0].client_number,'client_email': selectedESR[0].client_email,'other_email': selectedESR[0].other_email,'cost_codes':selectedESR[0].cost_codes,'mileage':selectedESR[0].mileage,'work_description':selectedESR[0].work_description,'equipment_information':selectedESR[0].equipment_information,'testo':selectedESR[0].testo,'calibration_full_gear':selectedESR[0].calibration_full_gear,'manometer':selectedESR[0].manometer,'CAL_gas':selectedESR[0].CAL_gas,'roles':['default']})
    }

    async handleCompanyChange(event) {
        this.setState({companyString: {'label': event.name, 'value': event.id}, client: event.id, client_location: event.city + ", " + event.state + ", " + event.country});
    }

    async removeCostCodeRow(row) {
        if(row.field === 'remove') {
            this.setState({cost_codes: this.state.cost_codes.filter((element) => {return element.id !== row.row.id} )});
        }
    }

    async removeEquipRow(row) {
        if(row.field === 'remove') {
            this.setState({equipment_information: this.state.equipment_information.filter((element) => {return element.id !== row.row.id} )});
        }
    }

    //The four methods below handle the bottom section of the ESR PDF. They all function in a similar way:
    //Check if the input was a checkbox, and get the value based on that
    //Copy the corresponding state variable
    //If the input was a checkbox, tie input to 'yes' key in dictionary. Otherwise set corresponding key (usually 'description') to value
    //Set state variable equal to copy using setState()
    handleTesto(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        var temp = {...this.state.testo};
        if(event.target.type === 'checkbox') {
            temp['yes'] = value;
        } else {
            temp['description'] = value;
        }
        this.setState({testo: temp});
    }

    handleCalibrationFullGear(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        var temp = {...this.state.calibration_full_gear};
        if(event.target.type === 'checkbox') {
            temp['yes'] = value;
        } else {
            temp['description'] = value;
        }
        this.setState({calibration_full_gear: temp});
    }

    handleManometer(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        var temp = {...this.state.manometer};
        if(event.target.type === 'checkbox') {
            temp['yes'] = value;
        } else {
            temp['description'] = value;
        }
        this.setState({manometer: temp});
    }

    handleCALGas(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        var temp = {...this.state.CAL_gas};
        if(event.target.type === 'checkbox') {
            temp['yes'] = value;
        } else {
            switch(event.target.name) {
                case 'CAL_gas_value1':
                    temp['value1'] = value;
                    break;
                case 'CAL_gas_value2':
                    temp['value2'] = value;
                    break;
                case 'CAL_gas':
                    temp['description'] = value;
                    break;
                default: break;
            }  
        }
        this.setState({CAL_gas: temp});
    }

    handleCostCodes(event) {
        if(this.state.cost_codes.length >= 7) { //ensure the amount of cost codes stays under 8 so they all fit on the pdf
            alert('You may only add seven rows to the Cost Code section of this form.');
            return;
        }
        costCodeID = Date.now() // simplest way to ensure a unique ID for each row; Date.now() returns the number of milliseconds elapsed since Jan 1, 1970.
        //copy the cost codes array, add new data, then set the state to the new array. Clear out used values
        var cost_codes_copy = [...this.state.cost_codes];
        cost_codes_copy.push({'id': costCodeID,'employee': this.state.employee, 'hours': this.state.hours, 'code_number': this.state.code_number});
        this.setState({cost_codes: cost_codes_copy, employee: '', hours: '', code_number: ''});
    }

    handleEquipmentInformation(event) {
        //ensure the amount of equipment information stays under 8 so they all fit on the pdf
        if(this.state.equipment_information.length >= 7) {
            alert('You may only add seven rows to the Equipment Information section of this form.');
            return;
        }
        equipID = Date.now()  // simplest way to ensure a unique ID for each row; Date.now() returns the number of milliseconds elapsed since Jan 1, 1970.
        //copy the equipment information array, add new data, then set the state to the new array. Clear out used values
        var equipment_info_copy = [...this.state.equipment_information];
        equipment_info_copy.push({'id': equipID, 'item_number': this.state.item_number, 'quantity': this.state.quantity, 'equipment_description': this.state.equipment_description});
        this.setState({equipment_information: equipment_info_copy, item_number: '', quantity: '', equipment_description: ''});
    }

    checkRequired() {
        var alertNeeded = false;
        var alertOutput = "Please fill out all the required fields. Currently the following required fields are empty:";
        var requiredFields = ['date', 'job_number', 'work_description', 'mileage', 'client'];
        for(var i = 0; i < requiredFields.length; i++) {
            if(this.state[requiredFields[i]] === '') {
                alertOutput += ('\n' + requiredFields[i].replace('_', ' '));
                document.getElementById(requiredFields[i]).style.border = '2px solid red';
                alertNeeded = true;
            } else {
                document.getElementById(requiredFields[i]).style.border = 'none';
            }
        }
        if(alertNeeded) {
            alert(alertOutput);
        }
        return alertNeeded;
    }

    async createESR() {
        let param = {"date":this.state.date,"job_number":this.state.job_number,"client":this.context.company.id,"client_location":this.context?.location?.name ? this.context.location.name : '',"client_representative":this.state.client_representative,"client_number":this.state.client_number,"client_email":this.state.client_email,"other_email":this.state.other_email,"cost_codes":this.state.cost_codes,"mileage":this.state.mileage,"work_description":this.state.work_description,"equipment_information":this.state.equipment_information,"testo":this.state.testo,"calibration_full_gear":this.state.calibration_full_gear,"manometer":this.state.manometer,"CAL_gas":this.state.CAL_gas,"roles":['default'], "status": 'ongoing', "approved_date": '', "rejections": [{"reject_date": '', "reject_reason": ''}], "pending_date": ''}
        var response = await fetch(api_url + this.context?.company?.id + '/esrs', {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'POST', body: JSON.stringify(param)});
        var responseJSON = await response.json(); // ESR doc
        if (response.status === 200) {
            let blobResponse = await fetch(api_url + this.context?.company?.id + '/reports/03d14241-2f6e-4d0d-87b0-f2860973bca6/execute?esrid=' +  responseJSON.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'POST', body: []});
            let blobJSON = await blobResponse.json();
            if(blobResponse.status === 200) {
                responseJSON['blob_id'] = blobJSON.id;
                let updateResponse = await fetch(api_url + this.context?.company?.id + '/esrs/' + responseJSON.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(responseJSON)});
                let updateJSON = await updateResponse.json();
                if(updateResponse.status === 200) {
                    var job_response = await fetch(api_url + this.context?.company?.id + '/jobs/' + this.state.currentJobDocId, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'GET'})
                    var job_responseJSON = await job_response.json()
                    if(job_response.status === 200) {
                        job_responseJSON[0]['esrs'].push(updateJSON.id)
                        var job_update = await fetch(api_url + this.context?.company?.id + '/jobs/' + this.state.currentJobDocId, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(job_responseJSON[0])})
                        if(job_update.status === 200) {
                            this.setState({results : "SUCCESS"});
                        } else {
                            this.setState({results : "ERROR"});
                            alert("Error: " + job_update.status + "\nError message: " + job_update.message + "\nInvocation ID: " + job_update.invocation_id + "\nPlease include this error message when requesting support.");
                        }
                    }
                } else {
                    this.setState({results : "ERROR"});
                    alert("Error: " + updateJSON.status + "\nError message: " + updateJSON.message + "\nInvocation ID: " + updateJSON.invocation_id + "\nPlease include this error message when requesting support.");
                }
            } else {
                this.setState({results : "ERROR"});
                alert("Error: " + blobJSON.status + "\nError message: " + blobJSON.message + "\nInvocation ID: " + blobJSON.invocation_id + "\nPlease include this error message when requesting support.");
            }
        } else {
            this.setState({results : "ERROR"});
            alert("Error: " + responseJSON.status + "\nError message: " + responseJSON.message + "\nInvocation ID: " + responseJSON.invocation_id + "\nPlease include this error message when requesting support.");
        }
        document.getElementById('add_ESR_form').reset();
        this.setState({runningHandleSubmit: false, date: '', job_number: '', client_representative: '', client_number: '', client_email: '', other_email: '', cost_codes: [], employee: '', hours: '', code_number: '', mileage: '', work_description: '', equipment_information: [], item_number: '', quantity: '', equipment_description: '', testo: {'yes': false, 'description': ''}, calibration_full_gear: {'yes': false, 'description': ''}, manometer: {'yes': false, 'description': ''}, CAL_gas: {'value1': '', 'value2': '', 'yes': false, 'description': ''}, companyString: null});
        console.log("esr created")
    }

    handleSubmit(event) {
        event.preventDefault(); 
        this.setState({runningHandleSubmit: true});
        var alertNeeded = this.checkRequired();
        if(!alertNeeded) {
            var extantJob = this.state.jobs.filter(j => j.job_num === this.state.job_number)
            if(!extantJob[0]) { // if job is not present
                this.setState({jobCreationDialogDisplay: true});
                return
            } else {
                this.createESR()
                return
            }
        }
        this.setState({runningHandleSubmit: false});
    }

    /**
     * If the calibration's specified job did not already exist, the Job Creation dialog allows the user to create a new job by providing an approver's email address. Confirming kicks off this process.
     * @param {*} event Used to prevent the window from refreshing. I'm not sure it's necessary here.
     */
    async jobCreationDialogConfirm(event) { 
        var job_doc = 
        {
            "job_num": this.state.job_number, 
            "status": "ongoing",
            "equipment": [],
            "esrs": [],
            "creation_date": new Date().toISOString(),
            "pending_date": "",
            "approved_date": "",
            "approver_email": this.state.approverEmail,
            "rejections": [],
            "roles": [ "default" ]
        }
        let post_response = await fetch(api_url + this.context.company.id + '/jobs/', {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'POST', body: JSON.stringify(job_doc)})
        var newJobID = await post_response.json()
        if(post_response.status !== 200) { // if put_response status is 'ok', else trigger alert dialog
            this.setState({results: "ERROR"});
            this.context.updateContext('DISPLAY_API_ALERT_DIALOG', await post_response.json())
        } else {
            this.setState({currentJobDocId: newJobID.id}, () => {
                this.createESR()
                document.getElementById('add_ESR_form').reset(); // clear the form
                this.setState({results: "SUCCESS"})
                event.preventDefault();  // keep page from refreshing
                this.jobCreationDialogClose(); // close the dialog
            });
        }
    }

    jobCreationDialogClose() {
        this.setState({jobCreationDialogDisplay: false, runningHandleSubmit: false});
    }

    render() {
        let outputDiv;
        if(this.state.results === 'SUCCESS') {
            outputDiv = <p className="successMessage">Success!</p>
        } else if(this.state.results === 'ERROR') {
            outputDiv = <p className="errorMessage">Failure!</p>
        } else if(this.state.results === 'CANCELLED') {
            outputDiv = <p className="errorMessage">Cancelled!</p>
        }

        return(
            <>
            <Dialog maxWidth='lg' fullWidth open={this.state.jobCreationDialogDisplay} onClose={this.jobCreationDialogClose}>
                <DialogTitle>Create New Job?</DialogTitle>
                <DialogContent>
                    <DialogContentText>You are about to create a new job, are you sure?</DialogContentText>
                    <DialogContentText>If would like to create a new job, enter the approver's email address below and press 'Create Job'</DialogContentText>
                    <Input type='email' onChange={(event) => this.setState({approverEmail: event.target.value})}></Input>
                </DialogContent>
                <DialogActions>
                    <Button variant="custom-red" id="submit" value="confirm" onClick={this.jobCreationDialogConfirm}>Create Job</Button>
                    <Button variant="custom-red" id="submit" value="cancel" onClick={this.jobCreationDialogClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
                <br /><br /><br />
                <h1 id="top-header">Add ESR</h1>
                <div className="AddESR">
                    <form id="add_ESR_form" method="POST" onSubmit={this.handleSubmit}>
                    <h3 style={{color: 'white'}}>General Information</h3>
                        <div className="esr_info_section">
                            <div className="esr_info_column1">
                                <label htmlFor="date">Date:</label>
                                <input type="date" id="date" name="date" value={this.state.date} onChange={this.handle}/>
                                <label htmlFor="job_number">Job Number:</label>
                                <Tooltip title="Must be either 5 or 7 characters long">
                                <input type="text" id="job_number" name="job_number" value={this.state.job_number} onBlur={this.handleJobNum} onChange={this.handle}/>
                                </Tooltip>
                                <label htmlFor="client">Client:</label>
                                <Tooltip title="Select a company from the navbar to change this field">
                                    <input disabled style={{opacity: '80%'}} className="esrClient" id="client" value={this.context?.company?.name}></input>
                                </Tooltip>
                                <label htmlFor="client_location">Client Location:</label>
                                <Tooltip title="Select a location from the navbar to change this field">
                                <input disabled style={{opacity: '80%'}} type="text" id="client_location" name="client_location" value={this.context?.location?.name}/>
                                </Tooltip>
                            </div>
                            <div className="esr_info_column2">
                                <label htmlFor="client_representative">Client Representative:</label>
                                <input type="text" id="client_representative" name="client_representative" value={this.state.client_representative} onChange={this.handle}/>
                                <label htmlFor="client_number">Client Number:</label>
                                <input type="text" id="client_number" name="client_number" value={this.state.client_number} onChange={this.handle}/>
                                <label htmlFor="client_email">Client Email:</label>
                                <input type="text" id="client_email" name="client_email" value={this.state.client_email} onChange={this.handle}/>
                                <label htmlFor="other_email">Other's Email:</label>
                                <input type="text" id="other_email" name="other_email" value={this.state.other_email} onChange={this.handle}/> 
                            </div>   
                        </div><br/><br/>
                        <h3 style={{color: 'white'}}>Cost Code Information</h3>
                        <div className="esr_cost_container">
                            <div className="esr_cost_section">
                                
                                <div className="esr_cost_column1">
                                    <label htmlFor="employee">Employee:</label>
                                    <input style={{flex:1.8}} type="text" id="employee" name="employee" value={this.state.employee} onChange={this.handle}/>
                                </div>
                                <div className="esr_cost_column2">
                                    <label htmlFor="hours">Hours:</label>
                                    <input style={{flex:.2}} type="text" id="hours" name="hours" value={this.state.hours} onChange={this.handle}/>
                                </div>
                                <div className="esr_cost_column3">
                                    <label htmlFor="code_number">Code Number:</label>
                                    <input style={{flex:.2}} type="text" id="code_number" name="code_number" value={this.state.code_number} onChange={this.handle}/>
                                </div>
                                <div className="esr_cost_column4">
                                    <Button id="submit" variant="custom-red" type="button" onClick={this.handleCostCodes} style={{width: 100+'%', marginBottom: 3+'px'}}>Add Cost Code</Button>
                                </div>
                                
                            </div>
                        </div>
                        <div className="esr_data_grid_container">
                            <div className="esr_data_grid" style={{ height: 510, width: '75%' }}>                
                                <DataGrid
                                    id="data-grid"
                                    rows={this.state.cost_codes}
                                    onCellClick={(newSelection) => {this.removeCostCodeRow(newSelection)}}
                                    columns={costDataGridColumns}
                                    pageSize={7}
                                    rowsPerPageOptions={[7]}
                                    disableMultipleSelection={true} 
                                />
                            </div>
                            <div className="cost_code_table_div">
                                <h4 style={{color:'white'}}>Cost Codes</h4>
                                <table className="cost_code_table">
                                    <tbody>
                                        <tr><td>10 Travel</td></tr>
                                        <tr><td>20 In House</td></tr>
                                        <tr><td>30 Drafting</td></tr>
                                        <tr><td>40 Field/Eng WK</td></tr>
                                        <tr><td>70 Panel Work</td></tr>
                                    </tbody>
                                </table>
                                <label style={{marginTop: 1+'em'}} htmlFor="mileage">Mileage:</label>
                                <input style={{width: 100+'%'}} type="text" id="mileage" name="mileage" value={this.state.mileage} onChange={this.handle}/>
                            </div>
                        </div>
                        <br/>
                        <div className="esr_work_description">
                            <h3 className="section-headers">Work Description</h3>
                            <textarea style={{width: 100 + "%", height: 20 + "em", borderRadius: 7+'px'}} id="work_description" name="work_description" value={this.state.work_description} onChange={this.handle}></textarea>
                        </div>
                        <br/><br/>
                        <h3 style={{color:'white', width:100+'%'}}>Equipment Used</h3>
                        <div className="esr_equipment_section">
                            <div className="esr_equipment_column1">
                                <label htmlFor="item_number">Item No:</label>
                                <input type="text" id="item_number" name="item_number" value={this.state.item_number} onChange={this.handle}/>
                            </div>
                            <div className="esr_equipment_column2">
                                <label htmlFor="quantity">QTY:</label>
                                <input type="text" id="quantity" name="quantity" value={this.state.quantity} onChange={this.handle}/>
                            </div>
                            <div className="esr_equipment_column3">
                                <label htmlFor="equipment_description">Equipment Description:</label>
                                <input type="text" id="equipment_description" name="equipment_description" value={this.state.equipment_description} onChange={this.handle}/>
                            </div>
                            <div className="esr_equipment_column4">
                                <Button id="submit" type="button" variant='custom-red' onClick={this.handleEquipmentInformation} style={{width: 100+ '%', marginBottom: 3+'px'}}>Add Equipment</Button>
                            </div>
                        </div>
                        <div className="esr_data_grid" style={{ height: 510, width: '100%', marginTop: 10+'px' }}>
                            <DataGrid
                                id="data-grid"
                                rows={this.state.equipment_information}
                                columns={equipDataGridColumns}
                                onCellClick={(newSelection) => {this.removeEquipRow(newSelection)}}
                                pageSize={7}
                                rowsPerPageOptions={[7]}
                                disableMultipleSelection={true} 
                            />
                        </div>
                        <br/>
                        <h3 style={{color: 'white', width: 100+'%'}}>Task Choice</h3>
                        <div className="esr_task_section">
                            <div className="esr_task_column1">
                                <label htmlFor="testo" id="top_task">Testo (Combustion Alyzr):</label>
                                <label htmlFor="calibration_full_gear">Calibration Full Gear:</label>
                                <label htmlFor="manometer">Manometer</label>
                                <div>
                                    <label htmlFor="CAL_gas" style={{marginRight: .75+'em', width:'auto'}}>CAL Gas:</label>
                                    <input type="text" style={{width: 6+'em', marginRight: .5+'em'}} id="CAL_gas_value1" name="CAL_gas_value1" value={this.state.CAL_gas.value1} onChange={this.handleCALGas}/>
                                    <input type="text" style={{width: 6+'em'}} id="CAL_gas_value2" name="CAL_gas_value2" value={this.state.CAL_gas.value2} onChange={this.handleCALGas}/>
                                </div>
                            </div>
                            <div className="esr_task_column2">
                                <input className="esr_yes_checkbox" type="checkbox" value={this.state.testo.yes} onChange={this.handleTesto} checked={this.state.testo.yes}/>
                                <input className="esr_yes_checkbox" type="checkbox" value={this.state.calibration_full_gear.yes} onChange={this.handleCalibrationFullGear} checked={this.state.calibration_full_gear.yes}/>
                                <input className="esr_yes_checkbox" type="checkbox" value={this.state.manometer.yes} onChange={this.handleManometer} checked={this.state.manometer.yes}/>
                                <input className="esr_yes_checkbox" style={{height: 2+'em', width: 2+'em'}} type="checkbox" value={this.state.CAL_gas.yes} onChange={this.handleCALGas} checked={this.state.CAL_gas.yes}/>
                            </div>
                            <div className="esr_task_column3">
                                <input type="text" id="testo" name="testo" value={this.state.testo.description} onChange={this.handleTesto}/>
                                <input type="text" id="calibration_full_gear" name="calibration_full_gear" value={this.state.calibration_full_gear.description} onChange={this.handleCalibrationFullGear}/>
                                <input type="text" id="manometer" name="manometer" value={this.state.manometer.description} onChange={this.handleManometer}/>
                                <input type="text" id="CAL_gas" name="CAL_gas" value={this.state.CAL_gas.description} onChange={this.handleCALGas}/>
                            </div>
                        </div>
                        <Button id='submit' variant='custom-red' type='submit' disabled={this.state.runningHandleSubmit || !this.context.appIsOnline} style={this.state.runningHandleSubmit || !this.context.appIsOnline ? {opacity: '50%', marginTop: '1%'} : {opacity: '100%', marginTop: '1%'}}>{this.state.runningHandleSubmit ? <CircularProgress size={24}/>: 'Submit'}</Button>
                        {outputDiv}
                    </form>
                    <br /><br />
                </div>
            </>
        );
    }
}
export default withAuth0(AddESR);