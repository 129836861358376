import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { DataGrid } from '@material-ui/data-grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions';
import SyntaxHighlighter from 'react-syntax-highlighter';
import '../styles/ESRApproval.css';
import SessionContext from './SessionContext';

const api_url = process.env.REACT_APP_API_URL

const jobColumns = [ // set up columns for left DataGrid (jobs)
    {field: 'job_num', headerName: 'Job Number', width: 160, editable: false},
    {field: 'creation_date', headerName: 'Creation Date', width: 280, editable: false},
    {field: 'status', headerName: 'Job Status', width: 160, editable: false}
];

const calColumns = [ // set up columns for bottom right DataGrid (calibrations)
    {field: 'status', headerName: ' ESR Status', width: 160, editable: false},
    {field: 'date', headerName: 'Creation Date', width: 180, editable: false},
    {field: 'approved_date', headerName: 'Approved Date', width: 260, editable: false}
];

class ESRApproval extends React.Component {
    static contextType = SessionContext
    constructor() {
        super();
        this.state = { // default states
            auth_token: null, user: null, jobs: null, jobNum: null, date: null, status: null, calCount: null, cal_IDs: [], calibrations: [], job: null, disable: true, openDialog: false, rejectReason: "", alertDialogDisplay: false, disableDownload: true
        }
        this.handleApprove = this.handleApprove.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleOngoing = this.handleOngoing.bind(this);
        this.handlePending = this.handlePending.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
        this.handleApproveSubmit = this.handleApproveSubmit.bind(this);
        this.alertDialogDisplay = this.alertDialogDisplay.bind(this);
        this.alertDialogClose = this.alertDialogClose.bind(this);
        this.alertDialogCopyToClipboard = this.alertDialogCopyToClipboard.bind(this);
        this.downloadESRPdf = this.downloadESRPdf.bind(this);
    }

    async componentDidMount() {
        this.setState({disable: true})
    }

    async componentDidUpdate() {
        if(this.context.companies && !this.context?.company && !this.state.firstLoad && this.context.companies.length === 1) {
            await this.context.updateContext('CHANGE_COMPANY', this.context.companies[0])
            this.setState({firstLoad: true})
        }
        if (this.context.company && this.context.company.id !== this.state.prevCompanyId) {
            this.setState({prevCompanyId: this.context.company.id, disable: true})
            this.fetchJobs();
            this.fetchESRs();
        }
    }

    /**
     * Does an API call to fetch all jobs for selected company and sets them in the 'jobs' state
     */
    async fetchJobs() {
        var job_response = await fetch(api_url + this.context.company.id + '/jobs', {headers: { Authorization: 'Bearer ' + this.context.authToken }, method: 'GET'})
        if(job_response.status === 200 || job_response.status === 404) { // if status is 'ok', store job document in 'jobs' state
            this.setState({jobs: await job_response.json()})
        } else { // if job_response status is NOT 'ok', trigger alert dialog and display the HTTP error response
                this.alertDialogDisplay(await job_response.json())
        } 
    }

    /**
     * Does an API call to fetch all ESRs for selected company and sets them in the 'esrs' state
     */
    async fetchESRs() {
        var esr_response = await fetch(api_url + this.context.company.id + '/esrs', {headers: { Authorization: 'Bearer ' + this.context.authToken }, method: 'GET'})
        if(esr_response.status === 200 || esr_response.status === 404) { // if status is 'ok', store esr document in 'esrs' state
            this.setState({esrs: await esr_response.json()})
        } else { // if job_response status is NOT 'ok', trigger alert dialog and display the HTTP error response
                this.alertDialogDisplay(await esr_response.json())
        } 
    }

    /**
     * Fetches all ESRs for the selected job and sets them in the 'esr_list' state
     */
    async fetchESR_List () {
        let esr_list = []
        for (const e of this.state.job.esrs) {
            let doc = (await (await fetch(api_url + this.context.company.id + '/esrs/' + e, {headers: { Authorization: this.context.authToken }})).json())
            esr_list = esr_list.concat(doc)
        }
        this.setState({esr_list: esr_list, disableDownload: false})
    }

    userIsSpectareTech() { // helper function simply makes the following a bit easier to read
        return this.context.user.spectare?.roles ? this.context.user.spectare.roles.includes('technician') : false
    }

    userIsCompanyAdmin() { // helper function simply makes the following a bit easier to read
        return this.context.user?.roles ? this.context.user.roles.includes('admin') : false
    }

    // Function for the data grid that gets information for the job selected out of the list
    selectJob = async row => {
        if (row === undefined || row.id === 'Loading...') { return } // if there is no row or the row id is set to 'loading'; then the rows aren't ready or don't exist, return.
        this.setState({selectedESR: [], disable: true, job:row.row, selectedJobIndex: row.rowIndex}, async () => {
            this.fetchESR_List();
        })
    }

    selectESR = async row => {
        if (row === undefined || row.id === 'Loading...') { return }
        this.setState({selectedESR: row.row, disable: false})
    }

    // Downloads the PDF when a user selects a row in the calibrations DataGrid
    async downloadESRPdf()  {
        if (this.state.selectedESR?.blob_id) {
            let blob = await (await fetch(api_url + this.context.company.id + '/blobs/' + this.state.selectedESR.blob_id + '/download', { headers: { Authorization: this.context.authToken }})).blob() // Gets the response and returns it as a blob
            window.open(URL.createObjectURL(blob), '_blank'); // open blob(PDF) in a new tab
        } else {
            this.alertDialogDisplay("No ESR selected to download")
        }        
    }
    
    // *** HANDLERS *** //
    async handleApprove() {
        // setStates for popup dialog
        this.setState({dialogTitle: "Approve ESR", dialogText: "approve"})
        this.setState({dialogMessage: "All \"approve\" keyword actions are considered a legal electronic signiture. For all purposes herein, an electronic or facsimile signature shall be deemed the same as an original signature;", dialogMessage2: "If you would like to continue, select continue. If not, click cancel."})
        this.setState({openDialog: true}) // opens Reject Popup Dialog
        
    }

    async handleApproveSubmit() {
        this.setState({continue: true})
        // Get selected job and set appr_date to the current datetime in ISO format
        var appr_date = new Date().toISOString()
        appr_date = appr_date.substring(0, 10)
        let esr_doc = {...this.state.selectedESR}
        if(esr_doc) { // if there is a valid JSON esr_doc
            var appr_document = esr_doc
            appr_document.status = "approved" // change status to "approved" and approved_date to the appr_date in the JSON 
            appr_document.approved_date = appr_date
        }
        else {
            this.alertDialogDisplay("Approving ESR failed") // if no esr_doc, trigger alert dialog
        }
        // api call to update
        let put_response = await fetch(api_url + this.context.company.id + '/esrs/' + this.state.selectedESR.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(appr_document)})
        if(put_response.status === 200) { // if put_response status is 'ok', else trigger alert dialog 
            this.updateESR(put_response) // call updateESR to avoid conflicting etags
        }
        else {
            this.alertDialogDisplay(await put_response.json())
        }
        this.handleDialogClose()
        this.fetchESR_List();
        this.setState({disable: true})
    }

    async handlePending() {
        const pend_date = new Date().toISOString()
        let esr_doc = {...this.state.selectedESR}
        if(esr_doc) { // if there is a valid JSON esr_doc
            var pend_document = esr_doc
            pend_document.status = "pending" // change status to "pending" and pending_date to the pend_date in the JSON 
            pend_document.pending_date = pend_date
        } else {
            this.alertDialogDisplay("There was an error setting this ESR to pending")
        }
        // api call to update
        let put_response = await fetch(api_url + this.context.company.id + '/esrs/' + this.state.selectedESR.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(pend_document)})
        if(put_response.status === 200) { // if put_response status is 'ok', else trigger alert dialog
            this.updateESR(put_response) // call updateESR to avoid conflicting etags
        } else {
            this.alertDialogDisplay(await put_response.json())
        }
        this.fetchESR_List();
        this.setState({disable: false})
    }

    async handleOngoing() {
        let esr_doc = {...this.state.selectedESR}
        if(esr_doc) { // if there is a valid JSON esr_doc
            var ongoing_document = esr_doc
            ongoing_document.status = "ongoing" // change status to "ongoing" and pending_date to the an empty string in the JSON 
            ongoing_document.pending_date = ""
        } else {
            this.alertDialogDisplay("There was an error setting this ESR to ongoing")
        }
        // api call to update
        let put_response = await fetch(api_url + this.context.company.id + '/esrs/' + this.state.selectedESR.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(ongoing_document)})
        if(put_response.status === 200) { // if put_response status is 'ok', else trigger alert dialog
            this.updateESR(put_response) // call updateESR to avoid conflicting etags
        }
        else {
            this.alertDialogDisplay(await put_response.json()) 
        }
        this.fetchESR_List();
        this.setState({disable: false})
    }

    handleReject() {
        // setStates for popup dialog
        this.setState({dialogTitle: "Reject Job", dialogMessage: "Please give a reason for rejecting this job", dialogText: "reject", openDialog: true})
    }

    handleDialogClose() {
        this.setState({openDialog: false}) // closes Reject Popup Dialog
    }

    // takes the HTTP response and updates the specific esr with the newly updated esr doc. this avoids conflicting etags
    updateESR(put_response) {
        var index = this.state.esrs.findIndex( // find the index of the selected esr
            x => x.id === this.state.selectedESR.id
        )
        var tempArray = [...this.state.esrs] // store esrs array state into a local variable for manipulation 
        put_response.json().then(data => {
            var dataJSON = JSON.stringify(data) // object to string, i think we need to do this to make splice play nice
            tempArray.splice(index, 1, JSON.parse(dataJSON)) // splice the array at the index of selected esr and replace with updated doc 
            this.setState({ esrs: tempArray, selectedESR: tempArray[index] }) // store our copy of the array with the updated doc into the esrs array state
        })
    }

    async handleRejectSubmit() {
        const rej_date = new Date().toISOString()
        let esr_doc = {...this.state.selectedESR}
        if(esr_doc) { // if there is a valid JSON esr_doc
            var rej_document = esr_doc
            rej_document.approved_date = ""
            rej_document.status = "rejected" // change status to "rejected" and reject_date to the rej_date in the JSON 
            let rej_data = {
                reject_date: rej_date,
                reject_reason: this.state.rejectReason
            }
            rej_document['rejections'].push(rej_data)
        } else {
            this.alertDialogDisplay("There was an error submitting rejection")
        }
        let put_response = await fetch(api_url + this.context.company.id + '/esrs/' + this.state.selectedESR.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(rej_document)})
        if(put_response.status === 200) { // if put_response status is 'ok', else close reject dialog and open trigger alert dialog
            this.updateESR(put_response) // call updateESR to avoid conflicting etags
        }
        else {
            this.alertDialogClose()
            this.alertDialogDisplay(await put_response.json())
        }
        this.fetchESR_List();
        this.setState({openDialog: false})
    }

    // *** POPUP DIALOG *** //
    alertDialogDisplay(message) {
        this.setState({alertDialogDisplay: true, alertDialogContent: message, alertCopiedToClipboard: false})
    }

    async alertDialogCopyToClipboard() {
        navigator.clipboard.writeText(JSON.stringify(this.state.alertDialogContent, null, 2));
        this.setState({alertCopiedToClipboard: true})
    }

    alertDialogClose(event) {
        this.setState({alertDialogDisplay: false, alertDialogContent: ''});
    }

    render() {
        let buttonDiv
        if(this.userIsCompanyAdmin() === true) {
            buttonDiv = <div id="esra_buttons">
                <input id="esra_approve" type="button" value="Approve" onClick={this.handleApprove} style={this.state.disable || !this.state?.selectedESR || this.state.selectedESR?.status === "approved" || this.state.selectedESR?.status === "ongoing" ? {backgroundColor: 'gray'} : {backgroundColor: '#029333'}} disabled={this.state.disable || !this.state?.selectedESR || this.state.selectedESR?.status === "approved" || this.state.selectedESR?.status === "ongoing"} />
                <input id="esra_reject" type="button" value="Reject" onClick={this.handleReject} style={this.state.disable || !this.state?.selectedESR || this.state.selectedESR?.status === "approved" || this.state.selectedESR?.status === "ongoing" ? {backgroundColor: 'gray'} : {backgroundColor: '#AF282E'}} disabled={this.state.disable || !this.state?.selectedESR || this.state.selectedESR?.status === "approved" || this.state.selectedESR?.status === "ongoing"} />
            </div>
        } else if(this.userIsSpectareTech() === true) {
            buttonDiv = <div id="esra_buttons">
                <input id="esra_ongoing" type="button" value="Ongoing" onClick={this.handleOngoing} style={this.state.disable || !this.state?.selectedESR || this.state.selectedESR?.status === "approved" || this.state.selectedESR?.status === "ongoing" ? {backgroundColor: 'gray'} : {backgroundColor: '#AF282E'}} disabled={this.state.disable || !this.state?.selectedESR || this.state.selectedESR?.status === "approved" || this.state.selectedESR?.status === "ongoing" }/>
                <input id="esra_pending" type="button" value="Pending" onClick={this.handlePending} style={this.state.disable || !this.state?.selectedESR || this.state.selectedESR?.status === "approved" || this.state.selectedESR?.status === "pending" ? {backgroundColor: 'gray'} : {backgroundColor: '#029333'}} disabled={this.state.disable || !this.state?.selectedESR || this.state.selectedESR?.status === "approved" || this.state.selectedESR?.status === "pending" }/>
            </div>
        }
        var dialogContent
        if(this.state.dialogText === "reject") {
            dialogContent = 
            <DialogContent>
                <DialogContentText>{this.state.dialogMessage}</DialogContentText>
                <TextField autoFocus rows="8" fullWidth id="reject" multiline
                    onChange={event => {
                        const { value } = event.target;
                        this.setState({ rejectReason: value })
                    }}
                />
                <Button onClick={this.handleRejectSubmit} variant="contained">Submit</Button>
            </DialogContent>
        } else if(this.state.dialogText === "approve") {
            dialogContent =
            <DialogContent>
                <DialogContentText>{this.state.dialogMessage}<br/><br/>{this.state.dialogMessage2}</DialogContentText>
                <Button onClick={this.handleApproveSubmit} variant="contained">Continue</Button>
                <Button onClick={this.handleDialogClose} variant="contained">Cancel</Button>
            </DialogContent>
        }

        return (
            <>
            <br /><br /><br /><br />
            <Dialog maxWidth='lg' fullWidth open={this.state.openDialog} onClose={this.alertDialogClose}>
                <DialogTitle>{this.state.dialogTitle}</DialogTitle>
                {dialogContent}
            </Dialog>
            <Dialog onClose={this.alertDialogClose} open={this.state.alertDialogDisplay}>
                <DialogTitle>Oops!</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>The server could not complete your request successfully, and returned the following error information:</DialogContentText>
                    <SyntaxHighlighter language="json">{JSON.stringify(this.state.alertDialogContent, null, 2)}</SyntaxHighlighter>
                    <DialogContentText>Please provide this information when requesting support.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.alertDialogCopyToClipboard}>{this.state.alertCopiedToClipboard ? "Copied!" : "Copy to Clipboard"}</Button>
                    <Button onClick={this.alertDialogClose}>Dismiss</Button>
                </DialogActions>
            </Dialog>
            <div id="esrApprovalPage">
                <div id="esra_leftPane">
                    <DataGrid
                        id="data-grid"
                        rows={this.state.jobs ? this.state.jobs.filter(j => j.esrs.length !== 0) : []}
                        columns={jobColumns}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        disableMultipleSelection={true} 
                        onRowClick={(newSelection) => {this.selectJob(newSelection)}}
                    />
                </div>
                <div id="esra_topRight">
                    <DataGrid
                        id="data-grid"
                        rows={this.state.esr_list ? this.state.esr_list : []}
                        columns={calColumns}
                        pageSize={8}
                        rowsPerPageOptions={[8]}
                        disableMultipleSelection={true} 
                        onRowClick={(newSelection) => {this.selectESR(newSelection)}}
                    />
                </div>
                <div id="esra_creationDate">
                    <h2 id="esra_header">Creation Date</h2>
                    <h1 id="esra_info">{this.state.selectedESR?.date ? this.state.selectedESR.date : ''}</h1>
                </div>
                <div id="esra_status">
                    <h2 id="esra_header">Status</h2>
                    <h1 id="esra_info">{this.state.selectedESR?.status ? this.state.selectedESR.status : ''}</h1>
                </div>
                <div id="esra_otherInfo">
                    <h2 id="esra_header">Other Info</h2>
                    <h3 id="esra_info">{this.state.selectedESR?.status === 'rejected' ? (this.state.selectedESR ? (this.state.selectedESR?.rejections?.length >= 1 ? this.state.selectedESR?.rejections[this.state.selectedESR?.rejections?.length - 1].reject_reason : 'N/A') : '') : 'N/A'}</h3>
                </div>
                {buttonDiv}
                <div id="esra_downloadESR">
                    <input id="esra_downloadBtn" type="button" value="Download ESR" onClick={this.downloadESRPdf} style={this.state.disableDownload ? {backgroundColor: 'gray'} : {backgroundColor: '#AF282E'}} disabled={this.state.disableDownload}/>
                </div>
            </div>
            </>
        );
    }
}
export default withAuth0(ESRApproval);