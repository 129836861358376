import React from 'react';
import Select from 'react-select';
import SessionContext from './SessionContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/manualLookup.css';
import { withAuth0 } from '@auth0/auth0-react';

const api_url = process.env.REACT_APP_API_URL
// const api_url = 'http://localhost:7071/api/'; // ONLY FOR LOCAL DEVELOPMENT

class ManualLookup extends React.Component {
    static contextType = SessionContext
    constructor() {
        super();
        this.state = { manualDropdownOptions: [], manuals: [], companySelectedOption: null, errorMessage: "", loadingManual: false };
    } 

    async componentDidMount() { 
        this.setState({childElement: <h4>Select a manual from the drop-down menu above and it will appear here.<br/><br/> If you know the name of the manual you are looking for, you can search for it in the drop-down menu</h4>})
        
    }

    async componentDidUpdate() {
        if(this.context.companies && !this.context?.company && !this.state.manualFirstLoad && this.context.companies.length === 1) {
            await this.context.updateContext('CHANGE_COMPANY', this.context.companies[0])
            this.setState({manualFirstLoad: true})
        }
        if(this.context.company && this.context.company.id !== this.state.prevCompanyId) { // if a company has been selected and it's not the same company that was already selected
            this.setState({prevCompanyId: this.context.company.id, manualSelectedOption: '' , childElement: <h4>Select a manual from the drop-down menu above and it will appear here.<br/><br/> If you know the name of the manual you are looking for, you can search for it in the drop-down menu</h4> });
            this.selectCompany(); // call to update manual list when a new company is selected
        }
    }

    async selectCompany() {
        this.setState({manualSelectedOption: null, manualDropdownOptions: []}, async () => { // update the selected option in state
            this.setState({manuals : await (await fetch(api_url + this.context.company.id + '/manuals', {headers: { Authorization: 'Bearer ' + this.context.authToken }, method: 'GET'})).json()}, async () => {
                let manualsDropdownHolder = this.state.manuals.map(m => {m['label'] = m.name; m['value'] = m.id; return m;})
                this.setState({manualDropdownOptions: manualsDropdownHolder}, async () => {}); 
            });
        });
    };

    // Download manual and create a blob 
    async downloadPDF(selectedOption) {
        this.setState({manualSelectedOption: selectedOption, loadingManual: true}); // set loading manual to true, this allows us to render a progress bar while the manual is loading
        // API call to download the selected manual
        let response = await fetch(api_url + this.context?.company.id + '/blobs/' + selectedOption.maintenance_manual + '/download', {headers: {Authorization: 'Bearer ' + this.context?.authToken}});
        if (response.status === 200) {
            let blob = await response.blob();
            let url = URL.createObjectURL(blob);
            this.setState({childElement: <object width="100%" style={{height : "85vh"}} type="application/pdf" data={url}>{''}</object>}, async () =>{
                this.setState({loadingManual: false}, async () =>{});
            })
        } else {
            if (response.status === 404) {
                alert("No document found.")
            } else {
                try {
                    let error_document = await response.json()
                    alert("Error: " + error_document.status + "\nError message: " + error_document.message + "\nInvocation ID: " + error_document.invocation_id + "\nPlease include this error message when requesting support.");
                } catch(error) {
                    alert('There was an unknown error! Contact support if needed.')
                }
            }
            this.setState({childElement: <h4>There was an error fetching your manual, please try again or submit a help ticket!</h4>}, async () =>{
                this.setState({loadingManual: false}, async () =>{});
            })
        }
    }

    handleChange = selectedOption => { this.downloadPDF(selectedOption); };

    render() {
        return (
            <>
                <br /><br /><br /><br />
                <div className="manualLookup">
                    <div id="manualDropdowns">
                        <h2 id="manualHeader">Manual Lookup</h2><br />
                        <div className="manual_inputs">
                            <Select
                                value={this.state.manualSelectedOption}
                                options={this.state.manualDropdownOptions}
                                onChange={this.handleChange}
                                isDisabled={!this.context.company && this.context?.user?.spectare !== undefined}
                                placeholder={this.context.company ? "Select a manual..." : "Select a company..."}
                            />
                        </div>
                    </div>
                    { this.state.errorMessage && <h2 className="error"> { this.state.errorMessage } </h2>}
                    <div id="pdfView" className="pdfView">
                        {this.state.loadingManual ? <CircularProgress size={64} /> : this.state.childElement} 
                    </div>
                </div>
            </>
        );
    }
}
export default withAuth0(ManualLookup);