import React, { useContext } from "react";
import SessionContext from './SessionContext';
import { DataGrid } from "@material-ui/data-grid";

const equipmentDataGridColumns = [
  {field: 'tag_number', headerName: 'Tag Number', flex: 1, editable: false},
  {field: 'area', headerName: 'Area', flex: 1, editable: false},
  {field: 'name', headerName: 'Name', flex: 1, editable: false},
  {field: 'location', headerName: 'Company Location', flex: 1, editable: false},
  {field: 'instrument_type', headerName: 'Type', flex: 1, editable: false},
  {field: 'site_location', headerName: 'Site Location', flex: 1, editable: false},
  {field: 'manufacturer', headerName: 'Manufacturer', flex: 1, editable: false},
  {field: 'model_number', headerName: 'Model Number', flex: 1, editable: false},
  {field: 'serial_number', headerName: 'Serial Number', flex: 1, editable: false}
];

export default function EquipmentDataGrid(props) {
  const session = useContext(SessionContext)

  return(
    <DataGrid
      id="data-grid"
      rows={session.location ? props.equipments.filter((element) => {return element.location === session.location.name}) : props.equipments}
      columns={equipmentDataGridColumns}
      pageSize={6}
      rowsPerPageOptions={[6]}
      disableMultipleSelection={true}
      onRowClick={props.onRowClick}
    />
  )
}