import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react'
import TopNav from './components/TopNav';
import Home from './components/Home';
import LandingBackground from './components/LandingBackground';
import RoleRouting from './components/RoleRouting';
import { Navbar } from 'react-bootstrap';
import logo from "./images/logo_iss_fullcolor.svg";
import './styles/App.css';
import { SessionContext } from './components/SessionContext';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';

const COMPANY_COLLECTIONS = ['users', 'blobs', 'reports', 'alerts', 'manuals', 'equipment', 'calibrations', 'pointsofcontact', 'esrs', 'jobs']

// TODO ALLOW WEBSITE USAGE EVEN WHEN AUTH0 DOESN'T WORK

/**
 * this is where we begin rendering React components. Although it's not strictly necessary, we attempt to centralize Auth0 authentication checks here. The rest of the application should be mostly authentication-agnostic.
 * @returns ... a component tree, I think?
 */
export default function App() {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [ authToken, setAuthToken ] = useState(""); // the auth token received from the Auth0 framework.
  const [ user, setUser ] = useState(""); // this is the user document we get from the API userinfo call.
  const [ appIsOnline, setAppIsOnline ] = useState(true); // used by some other components for offline logic.
  const [ companies, setCompanies] = useState(); // an array of companies that the user can access.
  const [ company, setCompany ] = useState(); // the current and globally-selected company for child components to consume (and change!)
  const [ location, setLocation ] = useState(); // as above, for (optional?) location location
  const [ cache, setCache ] = useState({buttonText: "Force Cache", inProgress: false}); // an object for storing everything we need for manual caching--note that the useState hook does not shallow merge, so all updates to this variable need to include the entire object.
  const [ apiAlertDialog, setApiAlertDialog] = useState({open: false, content: ''})
  const [ apiAlertDialogCopied, setApiAlertDialogCopied] = useState(false)

  const updateContext = async (actionType, payload) => {
    switch (actionType) {
      case 'CHANGE_COMPANY':
        let company = await (await fetch(process.env.REACT_APP_API_URL + payload.id, {headers: { Authorization: 'Bearer ' + authToken }, method: 'GET'})).json()
        setCompany(company)
        setCache({buttonText: "Force Cache", inProgress: false})
        return
      case 'CHANGE_LOCATION':
        setLocation(payload)
        return
      case 'FORCE_CACHE':
        forceCache()
        return
      case 'DISPLAY_API_ALERT_DIALOG':
        setApiAlertDialog({open: true, content: payload})
        setApiAlertDialogCopied(false)
        return
      default:
        console.log('updateContext received a message with an unknown actionType: ' + actionType)
        return
    }
  }

  async function forceCache() {
    document.activeElement.blur() // I don't fully understand why this removes focus from the element, but it does.
    let fetchTotal = 0
    let fetchCount = 0
    for (const collection of COMPANY_COLLECTIONS) {
      fetchTotal += company[collection].length + 1
    }
    setCache({buttonText: fetchCount + " of " + fetchTotal + "...", inProgress: true})
    for (const collection of COMPANY_COLLECTIONS) {
      console.log('now caching collection: ' + collection)
      let response = await fetch(process.env.REACT_APP_API_URL + company.id + '/' + collection, {headers: { Authorization: 'Bearer ' + authToken }, method: 'GET'}) // get all
      if ([200, 404].includes(response.status)) { fetchCount += 1 }
      for (const item of company[collection]) {
        let response = await fetch(process.env.REACT_APP_API_URL + company.id + '/' + collection + '/' + item, {headers: { Authorization: 'Bearer ' + authToken }, method: 'GET'}) // get each
        if ([200, 404].includes(response.status)) { fetchCount += 1 }
        setCache({buttonText: fetchCount + " of " + fetchTotal + "...", inProgress: true})
      }
    }
    setCache({buttonText: "Finished!", inProgress: true})
    if (fetchTotal > fetchCount) { alert("Not all data could be retrieved: " + (fetchTotal - fetchCount) + " fetches failed.") }
    setTimeout(() => {setCache({buttonText: "Force Cache", inProgress: false})}, 1000)
  }

  window.addEventListener("online", function(e) { // this appears to work at least when an android device is in airplane mode.
    setAppIsOnline(true) 
  }, false);

  window.addEventListener("offline", function(e) {
    setAppIsOnline(false)
  }, false);

  useEffect(() => {
    async function setAuthTokenState() {
      setAuthToken((await getIdTokenClaims()).__raw)
    }
    if (isAuthenticated) { setAuthTokenState() } // set our auth token for later use.
  }, [ isAuthenticated ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { // this runs when either of the dependencies change.
    async function setUserState() { // as above...
      setUser(await (await fetch(process.env.REACT_APP_API_URL + 'userinfo/', {headers: { Authorization: 'Bearer ' + authToken }, method: 'GET'})).json())
    }
    if (authToken) { setUserState() } // once the auth token is set, retrieve our user and set that as well
  }, [ authToken ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function setCompaniesState() {
      setCompanies(await (await fetch(process.env.REACT_APP_API_URL + user.company + '/companies/', {headers: { Authorization: 'Bearer ' + authToken }, method: 'GET'})).json())
    }
    if (user) { setCompaniesState() }
  }, [ user ]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SessionContext.Provider value={{ authToken, user, companies, company, location, appIsOnline, updateContext, cache }}> {/*provide user and auth token for any other component to use--anything else can be added here if multiple components will need it*/}
      <Dialog onClose={() => {setApiAlertDialog({open: false, content: ''})}} open={apiAlertDialog.open}> {/* this dialog can be opened by calling the context update function */}
        <DialogTitle>Oops!</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>The server could not complete your request successfully, and returned the following error information:</DialogContentText>
          <SyntaxHighlighter language="json">{JSON.stringify(apiAlertDialog.content, null, 2)}</SyntaxHighlighter>
          <DialogContentText>Please provide this information when requesting support.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="custom-red" onClick={async () => {navigator.clipboard.writeText(JSON.stringify(apiAlertDialog.content, null, 2)); setApiAlertDialogCopied(true)}}>{apiAlertDialogCopied ? "Copied!" : "Copy to Clipboard"}</Button>
          <Button variant="custom-red" onClick={() => {setApiAlertDialog({open: false, content: ''})}}>Dismiss</Button>
        </DialogActions>
      </Dialog>
      {isAuthenticated ? 
      <>
        <TopNav />
        <Switch>
          <RoleRouting />
        </Switch>
      </> : 
      <>
        <Navbar expand="lg" id="navbar" fixed="top" variant="dark" collapseOnSelect>
          <Navbar.Brand href="#home"><img alt="" src={logo} width="250px" id="logo" />Welcome to the Calibrations Dashboard!</Navbar.Brand>
        </Navbar>
        <LandingBackground />
        <Home />
      </>}
    </SessionContext.Provider>
  )
}