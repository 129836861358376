import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Auth0ProviderWithHistory from './components/Auth0ProviderWithHistory';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

// This is the root of our application--it's the first thing that gets rendered when the page loads. It calls the app, first wrapping it in our React Router, then in our Auth0Provider (which needs to be inside the Router)

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory> {/*we use React Router and can't hook it in the top level, so we need to wrap it in a component */}
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

reportWebVitals();