import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Row, Col, Form } from 'react-bootstrap';

export default function NullableToggle(props) {
  const [ toggleState, setToggleState ] = useState(props.value)

  useEffect(() => {
    if (props.value !== toggleState) {
      if (['', props.left, props.right].includes(props.value)) {
        setToggleState(props.value)
      }
    }
  }, [ props.value ]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row>
        <Col className="text-nowrap"><Form.Label column>{props.label}{": "}</Form.Label></Col>
        <Col className="text-nowrap">
          <ButtonGroup>
            <Button variant={toggleState === props.left ? "success" : "secondary"} onClick={() => {
              if (toggleState === props.left) {
                setToggleState("")
                props.callback("")
              } else {
                setToggleState(props.left)
                props.callback(props.left)
              }
            }}>{props.left}</Button>
            <Button variant={toggleState === props.right ? "danger" : "secondary"} onClick={() => {
              if (toggleState === props.right) {
                setToggleState("")
                props.callback("")
              } else {
                setToggleState(props.right)
                props.callback(props.right)
              }
            }}>{props.right}</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  )
}