import React from 'react';
import { Button } from 'react-bootstrap'
import Select from 'react-select';
import '../styles/Calibrations.css';
import { withAuth0 } from '@auth0/auth0-react';
import QRCode from 'react-qr-code';
import CircularProgress from '@material-ui/core/CircularProgress';
import SessionContext from './SessionContext';
import { Tooltip } from '@material-ui/core';
import MultipleValueTextInput from './MultipleValueTextInput';

const api_url = process.env.REACT_APP_API_URL
// const api_url = 'http://localhost:7071/api/'; // ONLY FOR LOCAL DEVELOPMENT

class AddEquipment extends React.Component {
    static contextType = SessionContext
    constructor(props) {
        super(props);
        this.state = {site_location: '', general_inspection: [], companyDropdown: [], manualDropdown: [], name: '', company: '', instrument_type: '', description: '',  location: '',    inspection_frequency: '',  testing_frequency: '',  manufacturer: '',    tag_number: '',   loop_number: '',    area: '',    model_number: '',    actuator_model_number: '',   positioner_model_number: '',     valve_body_model_number: '',     serial_number: '', manual: '',  pid: '',  wiring: '', type_descriptor: '', coString: null, manualString: null, manualDropdownString: "Please select a company...", runningHandleSubmit: false, results: '', companyDropdownString: 'Loading...', companiesLoaded: false, qrButton: null, QRCode: null}
        this.handle = this.handle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleManualChange = this.handleManualChange.bind(this);
        this.handleType = this.handleType.bind(this);
        this.checkRequired = this.checkRequired.bind(this);
        this.fillSelectedEquipment = this.fillSelectedEquipment.bind(this);
        this.getExistingEquipment = this.getExistingEquipment.bind(this);
    }

    async componentDidUpdate() {
        if(this.context.companies && !this.context?.company && !this.state.firstLoad && this.context.companies.length === 1) {
            await this.context.updateContext('CHANGE_COMPANY', this.context.companies[0])
            this.setState({firstLoad: true})
        }
        if(this.context.company && this.context.company.id !== this.state.prevCompanyId) { // if a company has been selected and it's not the same company that was already selected
            document.getElementById('addEquipmentForm').reset(); // clear the form
            this.setState({site_location: '', general_inspection: [], name: '', company: '', instrument_type: '', description: '', inspection_frequency: '', testing_frequency: '', manufacturer: '', tag_number: '', loop_number: '', area: '', model_number: '', actuator_model_number: '', positioner_model_number: '', valve_body_model_number: '', serial_number: '', manual: '', pid: '', wiring: '', type_descriptor: '', coString: '', manualString: ''});
            this.setState({actuator_serial: '', actuator_size: '', actuator_travel: '', actuator_type: '', actuator_bench_set: '', actuator_pressure_units: '', actuator_operating_range: '', body_serial: '', body_size: '', body_rating: '', body_plug: '', body_body: '', body_type: '', body_port_size: '', body_stem: '', body_seat: '', positioner_serial: '', positioner_operating_range: ''});
            this.setState({prevCompanyId: this.context.company.id, location: this.context?.location?.name, dropdownSelectedEquipment: null}, async () => { // set the new previous ID so we don't infinitely update
                this.handleCompanyChange(); // get updated list of manuals
                this.getExistingEquipment(); // get updated list of equipment
            });
        } else if(this.context.location && this.context.location.name !== this.state.prevLocationName) {
            this.setState({prevLocationName: this.context.location.name, dropdownSelectedEquipment: null}); 
        }
    }

    async componentDidMount() {
        this.setState({prevCompanyId: undefined, prevLocationName: undefined}) // when you navigate to the page or navigate away and back, set prevCompanyId and prevLocationName to undefined to allow the proper fetch of equipment
    }
    /**
     * Fetches equipment from DB and maps it to a dictionary for use with the dropdown
     */
    async getExistingEquipment() { // fetch from DB, map label/value pairs, then store in state
        var equipmentResponseJSON = await (await fetch(api_url  + this.context.company.id + '/equipment', {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'GET'})).json();
        var equipmentDictionary = equipmentResponseJSON.map(e => {e['label'] = e.tag_number + ' - ' + e.area; e['value'] = e.id; return e;});
        this.setState({existingEquipmentDropdown: equipmentDictionary});
    }

    handleManualChange(event) {
        var dict = {'label': event.label, 'value': event.value};
        this.setState({manualString: dict});
        this.setState({manual: event.value});
    }
    
    /**
     * Gets information for manuals based on selected company.
     */
    async handleCompanyChange() {
        this.setState({manualString: null, manualDropdown: [], coString: {'label': this.context.company.name, 'value': this.context.company.id}, company: this.context.company.id, manualDropdownString: "Loading..."});
        var manualResponseJSON = await (await fetch(api_url + this.context.company.id + "/manuals", {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'GET'})).json();
        let manualDictionary = manualResponseJSON.map(m => {m['label'] = m.name; m['value'] = m.id; return m;});
        this.setState({manualDropdown: manualDictionary, manualDropdownString: "Select..."});
    }

    handleType(event) {
        if(this.state.type_descriptor.includes(event.target.value)) {
            console.log(this.state.type_descriptor.replace(event.target.value, ''))
            this.setState({type_descriptor: this.state.type_descriptor.replace(event.target.value, '')});
        } else {
            console.log(this.state.type_descriptor.concat(event.target.value))
            this.setState({type_descriptor: this.state.type_descriptor.concat(event.target.value)});
        }
    }

    handle(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    //check the required fields to ensure that they're filled out.
    checkRequired() {
        var alertNeeded = false;
        //create output template and make required fields arrays based on if equipment is valve or not.
        var alertOutput = "Please fill out all the required fields. Currently the following required fields are empty:";
        var requiredValveFields = ['valve_body_model_number', 'actuator_model_number', 'positioner_model_number'];
        var requiredEquipmentFields = ['serial_number', 'model_number'];
        var requiredFields = ['type_descriptor', 'manufacturer', 'name', 'area', 'tag_number', 'description', 'site_location'];

        //add specific required fields to general required fields based on if they're a valve or not.
        document.getElementById('instrument_type').style.border = 'none';

        //check to see if equipment type is selected, and set border and output if not.
        if(this.state.instrument_type === '') {
            alertOutput = alertOutput + "\nequipment type";
            document.getElementById('instrument_type').style.border = '2px solid red';
            alertNeeded = true;
        } else {
            if(this.state.instrument_type === 'valve') {
                requiredFields = requiredFields.concat(requiredValveFields);
            } else {
                requiredFields = requiredFields.concat(requiredEquipmentFields);
            }
        }
        //loop through required fields to check if they're empty or not. If empty, add to alert output and border input field.
        for(var i = 0; i < requiredFields.length; i++) {
            if(!this.state[requiredFields[i]]) {
                alertOutput += ('\n' + requiredFields[i].replace('_', ' '));
                document.getElementById(requiredFields[i]).style.border = '2px solid red';
                alertNeeded = true;
            } else {
                document.getElementById(requiredFields[i]).style.border = 'none';
            }
        }
    
        if(alertNeeded) { alert(alertOutput); }
        return alertNeeded;
    }

    //download QR code ... works somehow. This code is directly from the react-qr-code github page.
    downloadQRCode() {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }

    /**
     * Gets the selected equipment from the DB and populates fields on the page. Then it prepares the data to be added to the document when handleUpdate gets called
     * @param {*} event
     */
    async fillSelectedEquipment(event) { 
        if(event?.id) { // if event.id exists
            this.setState({updatingEquipment: true, dropdownSelectedEquipment: event, site_location: ''}, async () => { // set updatingEquipment to true to disable the submit button and show the update button
                var selectedEquipment = await (await fetch(api_url  + this.context.company.id + '/equipment/' + event.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'GET'})).json(); // get the newest copy of the equipment
                this.setState({selectedEquipmentState: selectedEquipment[0]}); // store selected equipment in state for use in handleUpdate
                if(this.context.company?.locations) { // if session context has a list of locations, update the top nav location drop down with the selected equipment's location if it matches one in the list
                    this.context.updateContext('CHANGE_LOCATION', this.context.company.locations.filter(e => e.name === selectedEquipment[0].location)[0])
                }
                selectedEquipment[0].manual !== "" ? this.setState({existingManual: true}) : this.setState({existingManual: false}) // if the document does not contain an empty manual element, set existing equip to true, else false
                if( selectedEquipment[0].manual !== '' && this.state?.manualDropdown) {
                    let selectedManual = this.state.manualDropdown.filter((element) => {return element.value === selectedEquipment[0].manual})[0];
                    this.setState({manualString: {'label': selectedManual.label, 'value': selectedManual.value}, manual: selectedManual.value});
                }
                selectedEquipment[0].last_updated = new Date().toISOString(); // add last_updated date to document
                // set state of all required params to the value of each from selected equipment
                this.setState({actuator_serial: '', actuator_size: '', actuator_travel: '', actuator_type: '', actuator_bench_set: '', actuator_pressure_units: '', actuator_operating_range: '', body_serial: '', body_size: '', body_rating: '', body_plug: '', body_body: '', body_type: '', body_port_size: '', body_stem: '', body_seat: '', positioner_serial: '', positioner_operating_range: ''});
                this.setState({id: selectedEquipment[0].id, selectedEquipmentId: event.id, instrument_type: selectedEquipment[0].instrument_type, type_descriptor: selectedEquipment[0].type_descriptor, manufacturer: selectedEquipment[0].manufacturer, site_location: selectedEquipment[0]?.site_location,area: selectedEquipment[0].area, tag_number: selectedEquipment[0].tag_number, name: selectedEquipment[0].name, loop_number: selectedEquipment[0].loop_number, pid: selectedEquipment[0].pid, description: selectedEquipment[0].description, wiring: selectedEquipment[0].wiring, testing_frequency: selectedEquipment[0].testing_frequency, inspection_frequency: selectedEquipment[0].inspection_frequency, model_number: selectedEquipment[0].model_number, serial_number: selectedEquipment[0].serial_number, actuator_model_number: selectedEquipment[0].actuator_model_number, positioner_model_number: selectedEquipment[0].positioner_model_number, valve_body_model_number: selectedEquipment[0].valve_body_model_number})
                this.setState({ type_descriptor: selectedEquipment[0]?.type_descriptor, general_inspection: (selectedEquipment[0]?.general_inspection ? selectedEquipment[0].general_inspection : [])})
                if(selectedEquipment[0]?.instrument_type === 'valve') {
                    console.log("VALVE")
                    this.setState({actuator_serial: selectedEquipment[0]?.actuator_serial, actuator_size: selectedEquipment[0]?.actuator_size, actuator_travel: selectedEquipment[0]?.actuator_travel, actuator_type: selectedEquipment[0]?.actuator_type, actuator_bench_set: selectedEquipment[0]?.actuator_bench_set, actuator_pressure_units: selectedEquipment[0]?.actuator_pressure_units, actuator_operating_range: selectedEquipment[0]?.actuator_operating_range, body_serial: selectedEquipment[0]?.body_serial, body_size: selectedEquipment[0]?.body_size, body_rating: selectedEquipment[0]?.body_rating, body_plug: selectedEquipment[0]?.body_plug, body_body: selectedEquipment[0]?.body_body, body_type: selectedEquipment[0]?.body_type, body_port_size: selectedEquipment[0]?.body_port_size, body_stem: selectedEquipment[0]?.body_stem, body_seat: selectedEquipment[0]?.body_seat, positioner_serial: selectedEquipment[0]?.positioner_serial, positioner_operating_range: selectedEquipment[0]?.positioner_operating_range});
                }
            })
        } else { // else reset the form
            document.getElementById('addEquipmentForm').reset();
            this.setState({site_location: '', general_inspection: [], name: '', company: '', instrument_type: '', description: '', location: '', inspection_frequency: '', testing_frequency: '', manufacturer: '', tag_number: '', loop_number: '', area: '', model_number: '', actuator_model_number: '', positioner_model_number: '', valve_body_model_number: '', serial_number: '', manual: '', pid: '', wiring: '', type_descriptor: '', coString: '', manualString: ''});
            this.setState({actuator_serial: '', actuator_size: '', actuator_travel: '', actuator_type: '', actuator_bench_set: '', actuator_pressure_units: '', actuator_operating_range: '', body_serial: '', body_size: '', body_rating: '', body_plug: '', body_body: '', body_type: '', body_port_size: '', body_stem: '', body_seat: '', positioner_serial: '', positioner_operating_range: ''});
            this.setState({updatingEquipment: false, dropdownSelectedEquipment: null})      
        }
    }

    //this.setState({actuator_serial: this.state.actuator_serial, actuator_size: this.state.actuator_size, actuator_travel: this.state.actuator_travel, actuator_type: this.state.actuator_type, actuator_bench_set: this.state.actuator_bench_set, actuator_pressure_units: this.state.actuator_pressure_units, actuator_operating_range: this.state.actuator_operating_range, body_serial: this.state.body_serial, body_size: this.state.body_size, body_rating: this.state.body_rating, body_plug: this.state.body_plug, body_body: this.state.body_body, body_type: this.state.body_type, body_port_size: this.state.body_port_size, body_stem: this.state.body_stem, body_seat: this.state.body_seat, positioner_serial: this.state.positioner_serial, positioner_operating_range: this.state.positioner_operating_range});
    //runs after the submit button is clicked.
    async handleSubmit(event) {
        event.preventDefault(); 
        this.setState({runningHandleSubmit: true, QRCode: null, qrButton: null});
        if(this.context.company) {
            var alertNeeded = this.checkRequired();
            //check if an alert was given to the user, meaning that one of the required fields is empty.
            if(!alertNeeded) {
                // create the dictionary to add to the document
                var param = {"name": this.state.name, "instrument_type": this.state.instrument_type, "description": this.state.description, "location": (this.context?.location ? this.context.location.name : ''), site_location: this.state.site_location, "area": this.state.area, "inspection_frequency": this.state.inspection_frequency, "testing_frequency": this.state.testing_frequency, "manufacturer": this.state.manufacturer, "tag_number": this.state.tag_number, "loop_number": this.state.loop_number, "model_number": this.state.model_number, "actuator_model_number": this.state.actuator_model_number, "positioner_model_number": this.state.positioner_model_number, "valve_body_model_number": this.state.valve_body_model_number, "serial_number": this.state.serial_number, "manual": this.state.manual, "pid": this.state.pid, "wiring": this.state.wiring, "type_descriptor": this.state.type_descriptor, "calibrations": [], "general_inspection": this.state.general_inspection, "roles": ["default"], "last_updated" : new Date().toISOString()};
                if(this.state.instrument_type === 'valve'){
                    //add valve comment fields to param for submit
                    let additionalParams = {"actuator_serial": this.state.actuator_serial, "actuator_size": this.state.actuator_size, "actuator_travel": this.state.actuator_travel, "actuator_type": this.state.actuator_type, "actuator_bench_set": this.state.actuator_bench_set, "actuator_pressure_units": this.state.actuator_pressure_units, "actuator_operating_range": this.state.actuator_operating_range, "body_serial": this.state.body_serial, "body_size": this.state.body_size, "body_rating": this.state.body_rating, "body_plug": this.state.body_plug, "body_body": this.state.body_body, "body_type": this.state.body_type, "body_port_size": this.state.body_port_size, "body_stem": this.state.body_stem, "body_seat": this.state.body_seat, "positioner_serial": this.state.positioner_serial, "positioner_operating_range": this.state.positioner_operating_range}
                    const returnedTarget = Object.assign(param, additionalParams);
                    param = returnedTarget;
                }
                //POST data to API. If return status is 200, create QR code for download. If return status is not 200, output error message.
                var response = await fetch((api_url  + this.context.company.id + '/equipment'), {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'POST', body: JSON.stringify(param)});
                var responseDocument = await response.json();
                if(response.status !== 200) {
                    alert("Error: " + responseDocument.status + "\nError message: " + responseDocument.message + "\nInvocation ID: " + responseDocument.invocation_id + "\nPlease include this error message when requesting support.")
                    this.setState({results : "ERROR"});
                } else {
                    var qr_code = <QRCode id="QRCode" value={'https://calibrations.iss-digital.com/calibrations/' + responseDocument.id} size={128}/>;
                    this.setState({qrCode: qr_code, qrButton: <Button id='form-buttons' variant="custom-red" type='button' disabled={!this.context.appIsOnline} onClick={this.downloadQRCode}>Generate QR Code</Button>, results : "SUCCESS"});
                }
                //reset the form data.
                document.getElementById('addEquipmentForm').reset();
                this.setState({site_location: '', general_inspection: [], name: '', dropdownSelectedEquipment: null, company: '', instrument_type: '', description: '', location: '', inspection_frequency: '', testing_frequency: '', manufacturer: '', tag_number: '', loop_number: '', area: '', model_number: '', actuator_model_number: '', positioner_model_number: '', valve_body_model_number: '', serial_number: '', manual: '', pid: '', wiring: '', type_descriptor: '', coString: '', manualString: '', runningHandleSubmit: false});
                this.setState({actuator_serial: '', actuator_size: '', actuator_travel: '', actuator_type: '', actuator_bench_set: '', actuator_pressure_units: '', actuator_operating_range: '', body_serial: '', body_size: '', body_rating: '', body_plug: '', body_body: '', body_type: '', body_port_size: '', body_stem: '', body_seat: '', positioner_serial: '', positioner_operating_range: ''});
            } else {
                this.setState({runningHandleSubmit: false, results: "ERROR"});
            }
        } else { // no company was found in context
            this.setState({runningHandleSubmit: false, results: "ERROR"});
            alert("Please select a company from the dropdown");
        }
    }

    /**
     * Update button handler; checks for what fields are required and updates the equipment document with any changes the user makes
     * @param {*} event 
     */
    async handleUpdate(event) {
        event.preventDefault()
        // create the dictionary to add to the document
        let param = {"name": this.state.name, "instrument_type": this.state.instrument_type, "description": this.state.description, "location": (this.context?.location ? this.context.location.name : ''), site_location: this.state.site_location,  "area": this.state.area, "inspection_frequency": this.state.inspection_frequency, "testing_frequency": this.state.testing_frequency, "manufacturer": this.state.manufacturer, "tag_number": this.state.tag_number, "loop_number": this.state.loop_number, "model_number": this.state.model_number, "actuator_model_number": this.state.actuator_model_number, "positioner_model_number": this.state.positioner_model_number, "valve_body_model_number": this.state.valve_body_model_number, "serial_number": this.state.serial_number, "manual": this.state.manual, "pid": this.state.pid, "wiring": this.state.wiring, "type_descriptor": this.state.type_descriptor, "general_inspection": this.state.general_inspection, "last_updated" : new Date().toISOString()};
        if(this.state.instrument_type === 'valve'){
            //add valve comment fields to param for submit
            let additionalParams = {"actuator_serial": this.state.actuator_serial, "actuator_size": this.state.actuator_size, "actuator_travel": this.state.actuator_travel, "actuator_type": this.state.actuator_type, "actuator_bench_set": this.state.actuator_bench_set, "actuator_pressure_units": this.state.actuator_pressure_units, "actuator_operating_range": this.state.actuator_operating_range, "body_serial": this.state.body_serial, "body_size": this.state.body_size, "body_rating": this.state.body_rating, "body_plug": this.state.body_plug, "body_body": this.state.body_body, "body_type": this.state.body_type, "body_port_size": this.state.body_port_size, "body_stem": this.state.body_stem, "body_seat": this.state.body_seat, "positioner_serial": this.state.positioner_serial, "positioner_operating_range": this.state.positioner_operating_range}
            const returnedTarget = Object.assign(param, additionalParams);
            param = returnedTarget;
        }
        for(var p of Object.keys(param).sort()) { // sort through all keys in param
            if(Object.keys(this.state.selectedEquipmentState).sort().includes(p)){ // if a key in the selected equipment matches a key in param
                this.state.selectedEquipmentState[p] = param[p]; // set the value from the selected equipment
            }
        }
        if(this.context.company) { // if a company has been selected
            var alertNeeded = this.checkRequired(event); // check if user missed any fields
            if(!alertNeeded) { // if no alert needed, submit the form
                this.setState({runningUpdate: true}); // set the loading circle to display in the update button
                var put_response = await (await fetch((api_url + this.context.company.id + '/equipment/' + this.state.selectedEquipmentId), {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(this.state.selectedEquipmentState)}));
                if(put_response.status === 200) {
                    this.setState({results: "SUCCESS", updatingEquipment: false});
                    alert("Equipment updated successfully");
                    document.getElementById('addEquipmentForm').reset();
                    this.setState({site_location: '', general_inspection: [], runningUpdate: false, name: '', dropdownSelectedEquipment: null, company: '', instrument_type: '', description: '', location: '', inspection_frequency: '', testing_frequency: '', manufacturer: '', tag_number: '', loop_number: '', area: '', model_number: '', actuator_model_number: '', positioner_model_number: '', valve_body_model_number: '', serial_number: '', manual: '', pid: '', wiring: '', type_descriptor: '', coString: '', manualString: ''});        
                    this.setState({actuator_serial: '', actuator_size: '', actuator_travel: '', actuator_type: '', actuator_bench_set: '', actuator_pressure_units: '', actuator_operating_range: '', body_serial: '', body_size: '', body_rating: '', body_plug: '', body_body: '', body_type: '', body_port_size: '', body_stem: '', body_seat: '', positioner_serial: '', positioner_operating_range: ''});
                    this.getExistingEquipment(); // re-fetch the equipment list
                } else {
                    alert("Error updating equipment");
                    this.setState({results : "ERROR", runningUpdate: false});
                }
            }
        } else {
            alert("Please select a company from the dropdown");
        }
    }
    
    async handleAdd(item, allItems){
        var currentItems = [];
        if(this.state?.general_inspection) { this.state.general_inspection.forEach(e => {if(!currentItems.includes(e) && e !== item){currentItems.push(e)}}) }
        currentItems.push(item);
        this.setState({"general_inspection": currentItems}, () => {console.log(this.state.general_inspection)}); 
    }

    async handleRemove(item, allItems){
        
        var currentItems = [];
        if(this.state?.general_inspection) { 
            this.state.general_inspection.forEach(e => {
                if(!currentItems.includes(e) && e !== item){
                    currentItems.push(e)
                }
            }) 
        }
        this.setState({"general_inspection": currentItems}, () => {console.log(this.state.general_inspection)}); 
    }

    render() {
        const instrument = this.state.instrument_type;
        var instrument_div;
        let outputDiv;
        let updateDiv;

        if(this.state.updatingEquipment){
            updateDiv = <Button id='form-buttons' variant="custom-red" type='button' disabled={!this.context.appIsOnline || this.state.runningUpdate} onClick={this.handleUpdate}>{this.state.runningUpdate ? <CircularProgress size={24}/>: 'Update'}</Button>
        }

        if(this.state.results === 'SUCCESS') {
            outputDiv = <p className="successMessage">Success!</p>
        } else if(this.state.results === 'ERROR') {
            outputDiv = <p className="errorMessage">Failure!</p>
        }

        if(instrument === 'valve') {
            instrument_div = <>
                <div id="instrumentDiv">
                    <div className="generalLabels">
                    <label htmlFor="valve_body_model_number">Valve Body Model Number: </label>
                    <input type="text" id="valve_body_model_number" name="valve_body_model_number" value={this.state.valve_body_model_number} onChange={this.handle}/>
                    </div>
                    <div className="generalLabels">
                        <label htmlFor="actuator_model_number">Actuator Model Number: </label>
                        <input type="text" id="actuator_model_number" name="actuator_model_number" value={this.state.actuator_model_number} onChange={this.handle}/>
                    </div>
                    <div className="generalLabels">
                        <label htmlFor="positioner_model_number">Positioner Model Number: </label>
                        <input type="text" id="positioner_model_number" name="positioner_model_number" value={this.state.positioner_model_number} onChange={this.handle}/>
                    </div>
                </div>
                <div className="valve_comments" ref={this.setTextInputRef} id="valve_comments" >
                    <h2 className="section-headers">Specific Comments</h2>
                    <div className="col1">
                        <h3 className="section-headers">ACTUATOR</h3>
                        <label htmlFor="actuator_serial">Serial: </label>
                        <input type="text" id="actuator_serial" name="actuator_serial" value={this.state.actuator_serial} onChange={this.handle}/><br/>
                        <label htmlFor="actuator_size">Size: </label>
                        <input type="text" id="actuator_size" name="actuator_size" value={this.state.actuator_size} onChange={this.handle}/><br/>
                        <label htmlFor="actuator_travel">Travel: </label>
                        <input type="text" id="actuator_travel" name="actuator_travel" value={this.state.actuator_travel} onChange={this.handle}/><br/>
                        <label htmlFor="actuator_type">Type: </label>
                        <input type="text" id="actuator_type" name="actuator_type" value={this.state.actuator_type} onChange={this.handle}/><br/>
                        <label htmlFor="actuator_bench_set">Bench Set: </label>
                        <input type="text" id="actuator_bench_set" name="actuator_bench_set" value={this.state.actuator_bench_set} onChange={this.handle}/><br/>
                        <label htmlFor="actuator_pressure_units">Pressure Units: </label>
                        <input type="text" id="actuator_pressure_units" name="actuator_pressure_units" value={this.state.actuator_pressure_units} onChange={this.handle}/><br/>
                        <label htmlFor="actuator_operating_range">Operating Range: </label>
                        <input type="text" id="actuator_operating_range" name="actuator_operating_range" value={this.state.actuator_operating_range} onChange={this.handle}/><br/>
                    </div>
                    <div className="col2">
                        <h3 className="section-headers">BODY</h3>
                        <label htmlFor="body_serial">Serial: </label>
                        <input type="text" id="body_serial" name="body_serial" value={this.state.body_serial} onChange={this.handle}/><br/>
                        <label htmlFor="body_size">Size: </label>
                        <input type="text" id="body_size" name="body_size" value={this.state.body_size} onChange={this.handle}/><br/>
                        <label htmlFor="body_rating">Rating: </label>
                        <input type="text" id="body_rating" name="body_rating" value={this.state.body_rating} onChange={this.handle}/><br/>
                        <label htmlFor="body_plug">Plug: </label>
                        <input type="text" id="body_plug" name="body_plug" value={this.state.body_plug} onChange={this.handle}/><br/>
                        <label htmlFor="body_body">Body: </label>
                        <input type="text" id="body_body" name="body_body" value={this.state.body_body} onChange={this.handle}/><br/>
                        <label htmlFor="body_type">Type: </label>
                        <input type="text" id="body_type" name="body_type" value={this.state.body_type} onChange={this.handle}/><br/>
                        <label htmlFor="body_port_size">Port Size: </label>
                        <input type="text" id="body_port_size" name="body_port_size" value={this.state.body_port_size} onChange={this.handle}/><br/>
                        <label htmlFor="body_stem">Stem: </label>
                        <input type="text" id="body_stem" name="body_stem" value={this.state.body_stem} onChange={this.handle}/><br/>
                        <label htmlFor="body_seat">Seat: </label>
                        <input type="text" id="body_seat" name="body_seat" value={this.state.body_seat} onChange={this.handle}/><br/>
                    </div>
                    <div className="col3">
                        <h3 className="section-headers">POSITIONER</h3>
                        <label htmlFor="positioner_serial">Serial: </label>
                        <input type="text" id="positioner_serial" name="positioner_serial" value={this.state.positioner_serial} onChange={this.handle}/><br/>
                        <label htmlFor="positioner_operating_range">Operating Range: </label>
                        <input type="text" id="positioner_operating_range" name="positioner_operating_range" value={this.state.positioner_operating_range} onChange={this.handle}/><br/>
                    </div>
                </div>
            </>
        } else {
            instrument_div = <div id="instrumentDiv">
                <div className="column1" >
                    <label htmlFor="model_number" >Model Number: </label>
                    <input type="text" id="model_number" name="model_number" value={this.state.model_number} onChange={this.handle}/>
                </div>
                <div className="column2">
                    <label htmlFor="serial_number">Serial Number: </label>
                    <input type="text" id="serial_number" name="serial_number" value={this.state.serial_number} onChange={this.handle}/>
                </div>
            </div>
        }

        return(
            <>
            <br /><br /><br /><br />
            <div className="addEquipment">
                <h2 id="equipment-header">Add Equipment</h2>
                <form id="addEquipmentForm" encType="multipart/form-data" method="POST" onSubmit={this.handleSubmit}>
                    <div className="equipmentDropdownDiv">
                        <label>Existing Equipment: 
                            <Select
                                className="selectStatements"
                                value={this.state.dropdownSelectedEquipment}
                                options={(this.state?.existingEquipmentDropdown && this.context?.location) ? this.state.existingEquipmentDropdown.filter((element) => {return element.location === this.context.location.name}) : this.state.existingEquipmentDropdown}
                                onChange={this.fillSelectedEquipment}
                                placeholder={this.state?.existingEquipmentDropdown ? "Select a piece of equipment..." : (this.context?.company ? "Loading..." : "Select a company...")}
                                isDisabled={!this.context?.company}
                                isClearable={true}
                            />    
                            * Optional
                        </label>
                        <label htmlFor="instrument_type">Instrument Type: 
                            <select className="equipmentSelects" id="instrument_type" name="instrument_type" value={this.state.instrument_type} onChange={this.handle} >
                                <option defaultValue>select an option</option>
                                <option value="switch">Switch</option>
                                <option value="trans">Trans</option>
                                <option value="valve">Valve</option>
                                <option value="mass_flow">Mass Flow</option>
                                <option value="vortex_flow">Vortex Flow</option>
                                <option value="o2_analyzer">O2 Analyzer</option>
                            </select>
                        </label>
                    </div>
                    <div id='type_descriptor' className="checkboxCSS">
                        <div>
                        <input type="checkbox" id="control" checked={this.state.type_descriptor?.includes('control')} name="type_descriptor" value="control" onChange={this.handleType}/>
                        </div>
                        <label htmlFor="control">Control</label>
                        <div>
                            <input type="checkbox" id="interlock" checked={this.state.type_descriptor?.includes('interlock')} name="type_descriptor" value="interlock" onChange={this.handleType}/>
                        </div>
                        <label htmlFor="interlock">Interlock</label>
                        <div>
                            <input type="checkbox" id="alarm" checked={this.state.type_descriptor?.includes('alarm')} name="type_descriptor" value="alarm" onChange={this.handleType}/>
                        </div>
                        <label htmlFor="alarm">Alarm</label>
                        <div >
                            <input type="checkbox" id="ind-rec" checked={this.state.type_descriptor?.includes('ind-rec')} name="type_descriptor" value="ind-rec" onChange={this.handleType}/>
                        </div>
                        <label htmlFor="ind-rec">Ind./Rec</label>
                    </div>
                    <div className="inputs">
                        
                    </div>
                    
                    <div className="column1">
                        <div className="generalLabels">
                            <label htmlFor="manufacturer">Manufacturer: </label>
                            <input type="text" id="manufacturer" name="manufacturer" value={this.state.manufacturer} onChange={this.handle}/>
                        </div>
                        <div className="generalLabels">
                            <label htmlFor="tag_number">Tag Number: </label>
                            <input type="text" id="tag_number" name="tag_number" value={this.state.tag_number} onChange={this.handle}/>
                        </div>
                        <div className="generalLabels">
                            <label htmlFor="name">Equipment Name: </label>
                            <input type="text" id="name" name="name" value={this.state.name} onChange={this.handle}/>
                        </div>
                        <div className="generalLabels">
                            <label htmlFor="pid">PID: </label>
                            <input type="text" id="pid" name="pid" value={this.state.pid} onChange={this.handle}/>
                        </div>
                        <div className="generalLabels">
                            <label htmlFor="wiring">Wiring: </label>
                            <input type="text" id="wiring" name="wiring" value={this.state.wiring} onChange={this.handle}/>
                        </div>
                        <div className="equipmentDropdownDiv">
                            <label htmlFor="testing_frequency">Testing Frequency: <br />
                                <select className="equipmentSelects" id="testing_frequency" name="testing_frequency" value={this.state.testing_frequency} onChange={this.handle}>
                                    <option defaultValue>select an option</option>
                                    <option value="annually">Annually</option>
                                    <option value="semiannually">Semiannually</option>
                                    <option value="quarterly">Quarterly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="biweekly">Biweekly</option>
                                    <option value="weekly">weekly</option>
                                </select>
                            </label>
                        </div>
                        <div className="equipmentDropdownDiv">
                            <label htmlFor="inspection_frequency">Inspection Frequency: <br />
                                <select className="equipmentSelects" id="inspection_frequency" name="inspection_frequency" value={this.state.inspection_frequency} onChange={this.handle}>
                                    <option defaultValue>select an option</option>
                                    <option value="annually">Annually</option>
                                    <option value="semiannually">Semiannually</option>
                                    <option value="quarterly">Quarterly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="biweekly">Biweekly</option>
                                    <option value="weekly">Weekly</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="column2">
                        <div className="generalLabels">
                            <label htmlFor="location">Company Location: </label>
                            <Tooltip title="Select a location from the navbar to change this field"> 
                            <input disabled style={{opacity: '80%'}} type="text" id="location" name="location" value={this.context.location?.name ? this.context.location.name : ""}/>
                            </Tooltip>
                        </div>
                        <div className="generalLabels">
                            <label htmlFor="area">Area: </label>
                            <input type="text" id="area" name="area" value={this.state.area} onChange={this.handle}/>
                        </div>
                        <div className="generalLabels">
                            <label htmlFor="site_location">Site Location: </label> 
                            <input type="text" id="site_location" name="site_location" value={this.state.site_location} onChange={this.handle}/>
                        </div>
                        <div className="generalLabels">
                            <label htmlFor="loop_number">Loop Number: </label>
                            <input type="text" id="loop_number" name="loop_number" value={this.state.loop_number} onChange={this.handle}/>
                        </div>
                        <label htmlFor="description" className="description">Description:</label>
                        <textarea style={{width: 90 + "%", height: 175 + "px"}} id="description" name="description" value={this.state.description} onChange={this.handle}></textarea>
                        <label>General Inspection Comments:</label>
                        <MultipleValueTextInput
                            onItemAdded={(item, allItems) => this.handleAdd(item,allItems)}
                            onItemDeleted={(item, allItems) => this.handleRemove(item, allItems)}
                            name="item-input"
                            placeholder="Enter whatever items you want; separate them with COMMA or ENTER."
                            values={this.state.general_inspection}
                        />
                    </div>
                    <div className="inputs">
                        <label htmlFor="manual">Maint. Manual: </label>
                        <Select
                            className="selectStatements"
                            value={this.state.manualString}
                            options={this.state.manualDropdown}
                            onChange={this.handleManualChange}
                            placeholder={this.context?.company ? (this.state?.manualDropdown ? (this.state.manualDropdown.length !== 0 ? "Select a manual...":"No manuals available...") : "Loading..."): "Select a company..."}
                            isDisabled={!this.context?.company || this.state?.manualDropdown?.length === 0}
                        />
                    </div>
                    {instrument_div}
                    <Button id='form-buttons' variant="custom-red" type='submit' disabled={this.state.runningHandleSubmit || this.state.updatingEquipment || !this.context.appIsOnline} style={(this.state.runningHandleSubmit || this.state.updatingEquipment || !this.context.appIsOnline) ? {opacity: '50%'} : {opacity: '100%'}}>{this.state.runningHandleSubmit ? <CircularProgress size={24}/>: 'Submit'}</Button>
                    {updateDiv}
                    {this.state.qrButton}
                    {outputDiv}
                </form>
                {this.state.qrCode}
            </div>
            </>);
    }
}

export default withAuth0(AddEquipment);