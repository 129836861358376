import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { DataGrid } from '@material-ui/data-grid';
import { PDFDocument } from 'pdf-lib'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions';
import SyntaxHighlighter from 'react-syntax-highlighter';
import '../styles/JobApproval.css';
import SessionContext from './SessionContext';
// const api_url = 'http://localhost:7071/api/'; // ONLY FOR LOCAL DEVELOPMENT
// const api_url = 'https://func-spectare-dev.azurewebsites.net/api/'; // prod
const api_url = process.env.REACT_APP_API_URL

const jobColumns = [ // set up columns for left DataGrid (jobs)
    {field: 'job_num', headerName: 'Job Number', width: 160, editable: false},
    {field: 'creation_date', headerName: 'Creation Date', width: 180, editable: false},
    {field: 'status', headerName: 'Status', width: 160, editable: false}
];

const calColumns = [ // set up columns for bottom right DataGrid (calibrations)
    {field: 'job_num', headerName: 'Job Number', width: 160, editable: false},
    {field: 'inspection_date', headerName: 'Inspection Date', width: 180, editable: false},
    {field: 'technician_name', headerName: 'Technician', width: 160, editable: false}
];


class JobApproval extends React.Component {
    static contextType = SessionContext
    constructor() {
        super();
        this.state = { // default states
            auth_token: null, user: null, jobs: null, jobNum: null, date: null, status: null, calCount: null, cal_IDs: [], calibrations: [], job: null, disable: true, openDialog: false, rejectReason: "", alertDialogDisplay: false, disableDownload: true
        }
        this.handleApprove = this.handleApprove.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleOngoing = this.handleOngoing.bind(this);
        this.handlePending = this.handlePending.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
        this.handleApproveSubmit = this.handleApproveSubmit.bind(this);
        this.downloadAll = this.downloadAll.bind(this);
        this.alertDialogDisplay = this.alertDialogDisplay.bind(this);
        this.alertDialogClose = this.alertDialogClose.bind(this);
        this.alertDialogCopyToClipboard = this.alertDialogCopyToClipboard.bind(this);
    }
    // When the page loads (or when react renders the DOM): get authentication, get and store user info, and jobs for the user's company
    async componentDidMount() {
        this.setState({disable: true})
    }

    async componentDidUpdate() {
        if(this.context.companies && !this.context?.company && !this.state.firstLoad && this.context.companies.length === 1) {
            await this.context.updateContext('CHANGE_COMPANY', this.context.companies[0])
            this.setState({firstLoad: true})
        }
        if (this.context.company && this.context.company.id !== this.state.prevCompanyId) {
            this.setState({prevCompanyId: this.context.company.id})
            var job_response = await fetch(api_url + this.context.company.id + '/jobs', {headers: { Authorization: 'Bearer ' + this.context.authToken }, method: 'GET'})
            if(job_response.status === 200 || job_response.status === 404) { // if status is 'ok', store job document in 'jobs' state
                this.setState({jobs: await job_response.json()})
            } else { // if job_response status is NOT 'ok', trigger alert dialog and display the HTTP error response
                    this.alertDialogDisplay(await job_response.json())
            }  
        }
    }

    userIsSpectareTech() { // helper function simply makes the following a bit easier to read
        return this.context.user.spectare?.roles ? this.context.user.spectare.roles.includes('technician') : false
    }

    userIsCompanyAdmin() { // helper function simply makes the following a bit easier to read
        return this.context.user?.roles ? this.context.user.roles.includes('admin') : false
    }
    // Counts number of calibrations in the job.
    countCalibrations() {
        let count = 0
        for (let i = 0; i < this.state.job?.equipment.length; i++) {
            count += this.state.job?.equipment[i]['calibrations'].length
        }
        return count
    }
    // Function for the data grid that gets information for the job selected out of the list
    selectJob = async row => {
        if (row === undefined || row.id === 'Loading...') { return } // if there is no row or the row id is set to 'loading'; then the rows aren't ready or don't exist, return.
        this.setState({job:row.row, selectedJobIndex: row.rowIndex, disable: (row.row.status !== 'ongoing') ? false : true}, async () => {
            let calibrations_list = []
            for (const eq of this.state.job.equipment) {
                calibrations_list = calibrations_list.concat(eq.calibrations)
            }
            let calibrations = await Promise.all(calibrations_list.map(async (id) => { // we await a promise.all here since array.map() seems to return an array of promises
                return (await (await fetch(api_url + this.context.company.id + '/calibrations/' + id, {headers: { Authorization: this.context.authToken }})).json())[0] // use each calibration id to fetch the respective calibration document']
            }))
            var voidedCount = calibrations.filter((element) => {return (element?.void === true)}).length;
            this.setState({calibrations: calibrations, disableDownload: false, calibrationCount: this.countCalibrations() - voidedCount})
        })
    }
    // Downloads the PDF when a user selects a row in the calibrations DataGrid
    downloadCalibrationPdf = async row => {
        if (row === undefined || row.id === 'Loading...') { return }
        let calibration = row.row // returns a single-length array for which the element is the calibration whose id matches uuid
        if (calibration) {
            let blob = await (await fetch(api_url + this.context.company.id + '/blobs/' + calibration.blob_id + '/download', { headers: { Authorization: this.context.authToken }})).blob() // Gets the response and returns it as a blob
            window.open(URL.createObjectURL(blob), '_blank'); // open blob(PDF) in a new tab
        } else {
            // console.log("downloadCalibrationPdf: couldn't select calibration!")
            this.alertDialogDisplay("Failed to download calibration")
        }        
    }
    // Function to handle the 'Download All' button
    async downloadAll() { 
        var cals = this.state.calibrations.filter((element) => {return (element?.void !== true)}) // store 'calibrations' state(array) into a var 'cals'
        const newPDF = await PDFDocument.create() // create a blank PDF 
        for (let index = 0; index < cals.length; index++) { // iterate for each calibration in 'cals'
            var cal = cals[index]; 
            // temp_blob = API call to fetch blob with id(cal.blob_id) in an ArrayBuffer
            this.setState({temp_blob: await (await fetch(api_url + this.context.company.id + '/blobs/' + cal.blob_id + '/download', { headers: { Authorization: this.context.authToken }})).arrayBuffer()}, async () => {
                // then load fetched PDF(temp_blob) to temp_doc
                this.setState({temp_doc: await PDFDocument.load(this.state.temp_blob)}, async () => {
                    // then copy the first page from temp_doc to donorPage
                    this.setState({donorPage: await newPDF.copyPages(this.state.temp_doc, [0])}, async () => {
                        // then add donorPage to newPDF
                        newPDF.addPage(this.state.donorPage[0]);
                        if(index === cals.length - 1){ // if there are no more PDFs
                            var pdfBytes = await newPDF.save() // save newPDF to pdfbytes (a Uint8Array)
                            var blobBuilder = new Blob([pdfBytes.buffer], { type: 'application/pdf'}) // create a new blob with pdfBytes and type PDF
                            window.open(URL.createObjectURL(blobBuilder), '_blank'); // open final PDF in new tab
                        }
                    });
                });
            });
        }
    }
    // *** HANDLERS *** //
    async handleApprove() {
        // setStates for popup dialog
        this.setState({dialogTitle: "Approve Job", dialogText: "approve"})
        this.setState({dialogMessage: "All \"approve\" keyword actions are considered a legal electronic signiture. For all purposes herein, an electronic or facsimile signature shall be deemed the same as an original signature;", dialogMessage2: "If you would like to continue, select continue. If not, click cancel."})
        this.setState({openDialog: true}) // opens Reject Popup Dialog
        
    }
    async handleApproveSubmit() {
        this.setState({continue: true})
        // Get selected job and set appr_date to the current datetime in ISO format
        const appr_date = new Date().toISOString() 
        // var job_doc = this.state.jobs[this.state.job]
        let job_doc = {...this.state.job}
        if(job_doc) { // if there is a valid JSON job_doc
            var appr_document = job_doc
            appr_document.status = "approved" // change status to "approved" and approved_date to the appr_date in the JSON 
            appr_document.approved_date = appr_date
        }
        else {
            this.alertDialogDisplay("Approving job failed") // if no job_doc, trigger alert dialog
        }
        // api call to update
        let put_response = await fetch(api_url + this.context.company.id + '/jobs/' + this.state.job.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(appr_document)})
        if(put_response.status === 200) { // if put_response status is 'ok', else trigger alert dialog 
            // console.log("Update successful")
            this.updateJob(put_response) // call update job to avoid conflicting etags
        }
        else {
            // console.log("Update failed")
            this.alertDialogDisplay(await put_response.json())
        }
        this.handleDialogClose()
        this.setState({disable: true})
    }
    async handlePending() {
        const pend_date = new Date().toISOString()
        let job_doc = {...this.state.job}
        if(job_doc) { // if there is a valid JSON job_doc
            var pend_document = job_doc
            pend_document.appr_date = ""
            pend_document.status = "pending" // change status to "pending" and pending_date to the pend_date in the JSON 
            pend_document.pending_date = pend_date
        } else {
            this.alertDialogDisplay("There was an error setting this job to pending")
        }
        // api call to update
        let put_response = await fetch(api_url + this.context.company.id + '/jobs/' + this.state.job.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(pend_document)})
        if(put_response.status === 200) { // if put_response status is 'ok', else trigger alert dialog
            // console.log("Update successful")
            this.updateJob(put_response) // call update job to avoid conflicting etags
        } else {
            // console.log("Update failed")
            this.alertDialogDisplay(await put_response.json())
        }
        this.setState({disable: false})
    }
    async handleOngoing() {
        let job_doc = {...this.state.job}
        if(job_doc) { // if there is a valid JSON job_doc
            var ongoing_document = job_doc
            ongoing_document.status = "ongoing" // change status to "ongoing" and pending_date to the an empty string in the JSON 
            ongoing_document.pending_date = ""
        } else {
            this.alertDialogDisplay("There was an error setting this job to ongoing")
        }
        // api call to update
        let put_response = await fetch(api_url + this.context.company.id + '/jobs/' + this.state.job.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(ongoing_document)})
        if(put_response.status === 200) { // if put_response status is 'ok', else trigger alert dialog
            // console.log("Update successful")
            this.updateJob(put_response) // call update job to avoid conflicting etags
        }
        else {
            // console.log("Update failed")
            this.alertDialogDisplay(await put_response.json()) 
        }
        this.setState({disable: true})
    }
    handleReject() {
        // setStates for popup dialog
        this.setState({dialogTitle: "Reject Job", dialogMessage: "Please give a reason for rejecting this job", dialogText: "reject", openDialog: true})
    }
    handleDialogClose() {
        this.setState({openDialog: false}) // closes Reject Popup Dialog
    }
    // takes the HTTP response and updates the specific job with the newly updated job doc. this avoids conflicting etags
    updateJob(put_response) {
        var index = this.state.jobs.findIndex( // find the index of the selected job
            x => x.id === this.state.job.id
        )
        var tempArray = [...this.state.jobs] // store jobs array state into a local variable for manipulation 
        put_response.json().then(data => {
            var dataJSON = JSON.stringify(data) // object to string, i think we need to do this to make splice play nice
            tempArray.splice(index, 1, JSON.parse(dataJSON)) // splice the array at the index of selected job and replace with updated doc 
            this.setState({ jobs: tempArray, job: tempArray[index] }) // store our copy of the array with the updated doc into the jobs array state
        })
    }

    async handleRejectSubmit() {
        const rej_date = new Date().toISOString()
        let job_doc = {...this.state.job}
        if(job_doc) { // if there is a valid JSON job_doc
            var rej_document = job_doc
            rej_document.approved_date = ""
            rej_document.status = "rejected" // change status to "rejected" and reject_date to the rej_date in the JSON 
            let rej_data = {
                reject_date: rej_date,
                reject_reason: this.state.rejectReason
            }
            rej_document['rejections'].push(rej_data)
        } else {
            // TODO: error stuff
        }
        let put_response = await fetch(api_url + this.context.company.id + '/jobs/' + this.state.job.id, {headers: {Authorization: 'Bearer ' + this.context.authToken}, method: 'PUT', body: JSON.stringify(rej_document)})
        if(put_response.status === 200) { // if put_response status is 'ok', else close reject dialog and open trigger alert dialog
            // console.log("Update successful")
            this.updateJob(put_response) // call update job to avoid conflicting etags
        }
        else {
            this.alertDialogClose()
            this.alertDialogDisplay(await put_response.json())
            // console.log("Update failed")
        }
        this.setState({openDialog: false})
    }
    // *** POPUP DIALOG *** //
    alertDialogDisplay(message) {
        this.setState({alertDialogDisplay: true, alertDialogContent: message, alertCopiedToClipboard: false})
    }
    async alertDialogCopyToClipboard() {
        navigator.clipboard.writeText(JSON.stringify(this.state.alertDialogContent, null, 2));
        this.setState({alertCopiedToClipboard: true})
    }
    alertDialogClose(event) {
        this.setState({alertDialogDisplay: false, alertDialogContent: ''});
    }

    render() {
        let buttonDiv
        if(this.userIsCompanyAdmin() === true) {
            buttonDiv = <div id="ja_buttons">
                <input id="ja_approve" type="button" value="Approve" onClick={this.handleApprove} style={!this.state?.job || this.state.job?.status === "approved" || this.state.job?.status === "ongoing" ? {backgroundColor: 'gray'} : {backgroundColor: '#029333'}} disabled={!this.state?.job || this.state.job?.status === "approved" || this.state.job?.status === "ongoing"} />
                <input id="ja_reject" type="button" value="Reject" onClick={this.handleReject} style={!this.state?.job || this.state.job?.status === "approved" || this.state.job?.status === "ongoing" ? {backgroundColor: 'gray'} : {backgroundColor: '#AF282E'}} disabled={!this.state?.job || this.state.job?.status === "approved" || this.state.job?.status === "ongoing"} />
            </div>
        } else if(this.userIsSpectareTech() === true) {
            buttonDiv = <div id="ja_buttons">
                <input id="ja_ongoing" type="button" value="Ongoing" onClick={this.handleOngoing} style={!this.state?.job || this.state.job?.status === "approved" || this.state.job?.status === "ongoing" ? {backgroundColor: 'gray'} : {backgroundColor: '#AF282E'}} disabled={!this.state?.job || this.state.job?.status === "approved" || this.state.job?.status === "ongoing" }/>
                <input id="ja_pending" type="button" value="Pending" onClick={this.handlePending} style={!this.state?.job || this.state.job?.status === "approved" || this.state.job?.status === "pending" ? {backgroundColor: 'gray'} : {backgroundColor: '#029333'}} disabled={!this.state?.job || this.state.job?.status === "approved" || this.state.job?.status === "pending" }/>
            </div>
        }

        var dialogContent

        if(this.state.dialogText === "reject") {
            dialogContent = 
            <DialogContent>
                <DialogContentText>{this.state.dialogMessage}</DialogContentText>
                <TextField autoFocus rows="8" fullWidth id="reject" multiline
                    onChange={event => {
                        const { value } = event.target;
                        this.setState({ rejectReason: value })
                    }}
                />
                <Button onClick={this.handleRejectSubmit} variant="contained">Submit</Button>
            </DialogContent>
        } else if(this.state.dialogText === "approve") {
            dialogContent =
            <DialogContent>
                <DialogContentText>{this.state.dialogMessage}<br/><br/>{this.state.dialogMessage2}</DialogContentText>
                <Button onClick={this.handleApproveSubmit} variant="contained">Continue</Button>
                <Button onClick={this.handleDialogClose} variant="contained">Cancel</Button>
            </DialogContent>
        }

        return (
            <>
            <br /><br /><br /><br />
            <Dialog maxWidth='lg' fullWidth open={this.state.openDialog} onClose={this.alertDialogClose}>
                <DialogTitle>{this.state.dialogTitle}</DialogTitle>
                {dialogContent}
            </Dialog>
            <Dialog onClose={this.alertDialogClose} open={this.state.alertDialogDisplay}>
                <DialogTitle>Oops!</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>The server could not complete your request successfully, and returned the following error information:</DialogContentText>
                    <SyntaxHighlighter language="json">{JSON.stringify(this.state.alertDialogContent, null, 2)}</SyntaxHighlighter>
                    <DialogContentText>Please provide this information when requesting support.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.alertDialogCopyToClipboard}>{this.state.alertCopiedToClipboard ? "Copied!" : "Copy to Clipboard"}</Button>
                    <Button onClick={this.alertDialogClose}>Dismiss</Button>
                </DialogActions>
            </Dialog>
            <div id="jobApprovalPage">
                <div id="ja_leftPane">
                    <DataGrid
                        id="data-grid"
                        rows={this.state.jobs != null ? this.state.jobs : []}
                        columns={jobColumns}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        disableMultipleSelection={true} 
                        onRowClick={(newSelection) => {this.selectJob(newSelection)}}
                    />
                </div>
                <div id="ja_jobNumber">
                    <h2 id="ja_header">Job Number</h2>
                    <h1 id="ja_info">{this.state.job?.job_num}</h1>
                </div>
                <div id="ja_jobType">
                    <h2 id="ja_header"># of Calibrations</h2>
                    <h1 id="ja_info">{this.state.job ? this.state.calibrationCount : ''}</h1>
                </div>
                <div id="ja_date">
                    <h2 id="ja_header">Creation Date</h2>
                    <h1 id="ja_info">{this.state.job ? (this.state.job?.creation_date.substring(0, 10) + " " + this.state.job?.creation_date.substring(11, 16)) : ''}</h1>
                </div>
                {buttonDiv}
                <div id="ja_bottom">
                    <DataGrid
                        id="data-grid"
                        rows={this.state.calibrations.length !== 0 ? this.state.calibrations.filter((element) => {return (element?.void !== true)}) : []}
                        columns={calColumns}
                        pageSize={8}
                        rowsPerPageOptions={[8]}
                        disableMultipleSelection={true} 
                        onRowClick={(newSelection) => {this.downloadCalibrationPdf(newSelection)}}
                    />
                </div>
                <div id="ja_downloadAll">
                    <input id="ja_downloadBtn" type="button" value="Download All" onClick={this.downloadAll} style={this.state.disableDownload ? {backgroundColor: 'gray'} : {backgroundColor: '#AF282E'}} disabled={this.state.disableDownload}/>
                </div>
            </div>
            </>
        );
    }
}
export default withAuth0(JobApproval);