import { createContext } from "react"

// This is a very simple context provider that can hold properties and provide them to any component that hooks it.
// It's already wrapping everything in App.js, so to hook it, include the following line in any component:
//
// const session = useContext(SessionContext)
//
// Then session is simply an object with useful properties, like session.user and session.authToken!

export const SessionContext = createContext()

// export const SessionContextProvider = SessionContext.Provider
export default SessionContext