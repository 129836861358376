import React, { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { Navbar, Nav, NavDropdown, Button, Container, Row, Col, Alert } from "react-bootstrap";
import logo from "../images/logo_iss_fullcolor.svg";
import logoutArrow from "../images/logoutArrow.jpg";
import { NavLink } from 'react-router-dom';
import SessionContext from './SessionContext';
import Select from 'react-select';
import "bootstrap/dist/css/bootstrap.css";
import '../styles/react-bootstrap-custom.css'
import '../styles/TopNav.css';

// This is our navbar at the top of the page. There's basically three ways it can render, based on what roles the user has.
// Note that we need to use React Router's Navlinks for navigating inside the application--using regular links causes the page to reload, which is contrary to the point of an SPA

export default function TopNav(props) {
  const { logout } = useAuth0();
  const session = useContext(SessionContext)
  const [ companySelectedOption, setCompanySelectedOption ] = useState()
  const [ locationSelectedOption, setLocationSelectedOption ] = useState()

  function userIs(str) { // helper function simply makes the following a bit easier to read
    return session?.user?.spectare?.roles ? session.user.spectare.roles.includes(str) : false
  }

  useEffect(() => {
    if (session.company !== companySelectedOption) { // we don't want to update the context if the user selected the same company again
      session.updateContext('CHANGE_COMPANY', companySelectedOption) // set the context equal to the value selected in the dropdown
      session.updateContext('CHANGE_LOCATION', undefined) // if the company changes the selected location should always reset
    }
    if (session.location !== locationSelectedOption) { // we don't want to update the context if the user selected the same location again
      session.updateContext('CHANGE_LOCATION', locationSelectedOption) // set the context equal to the value selected in the dropdown
    }
  }, [ companySelectedOption, locationSelectedOption ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (session.company !== companySelectedOption) { // we don't want to update the context if the user selected the same company again
      setCompanySelectedOption(session.company); // set the dropdown to the new value provided by the session
      setLocationSelectedOption(null) // if the company changes the selected location should always reset
    }
    if (session.location !== locationSelectedOption) { // we don't want to update the context if the user selected the same location again
      setLocationSelectedOption(session.location); // set the dropdown to the new value provided by the session
    }
  }, [ session.company, session.location ]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Navbar expand="lg" id="navbar" fixed="top" variant="dark" collapseOnSelect>
      <Nav>
        <Nav.Item>
          <Navbar.Brand><NavLink to="/"><img alt="" src={logo} width="250" id="logo" />{userIs("admin") ? "Admin Dashboard" : userIs("technician") ? "Technician Dashboard" : "Main Dashboard"}</NavLink></Navbar.Brand><Navbar.Toggle />
        </Nav.Item>
        <Navbar.Collapse>
          <Nav.Item>
            <NavDropdown title="Calibrations" id="drop">
              {userIs("admin") || userIs("technician") ? 
              <>
                <NavDropdown.Item ><NavLink to="/diagnostics/calibrations/add-calibration">Add Calibrations</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink to="/diagnostics/calibrations/add-equipment">Add and Update Equipment</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink to="/diagnostics/calibrations/add-esr">Add ESR</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink to="/diagnostics/calibrations/add-poc">Add Point of Contact</NavLink></NavDropdown.Item>
              </> : <></>}
              <NavDropdown.Item><NavLink to="/diagnostics/calibrations">Lookup Equipment and Calibrations</NavLink></NavDropdown.Item>
              <NavDropdown.Item><NavLink to="/diagnostics/manual-lookup">Lookup Manuals</NavLink></NavDropdown.Item>
              {session?.user?.roles?.includes('admin') || userIs("technician") || userIs("admin") ? 
              <>
                <NavDropdown.Item><NavLink to="/diagnostics/job-approval">Approve Jobs</NavLink></NavDropdown.Item>
                <NavDropdown.Item><NavLink to="/diagnostics/esr-approval">Approve ESRs</NavLink></NavDropdown.Item>
              </> : <></>}
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Companies" id="drop">
              <Container className="mx-2">
                <Row className="flex-nowrap align-items-center">
                  <Select 
                    styles={{container: provided => ({...provided, width: `${session.companies ? (session.companies.map((c) => {return c.name}).reduce((p, c) => p.length > c.length ? p : c, '').length) : 20}em`})}}
                    options={session.companies}
                    value={companySelectedOption}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    onChange={setCompanySelectedOption}
                    placeholder={session.companies ? "Select a company..." : "Loading..."}
                    isDisabled={session.companies === undefined || session.cache.inProgress}
                    isLoading={session.companies === undefined || session.cache.inProgress}
                  /> {/* Don't ask me why Col works on the following line but not here... */}
                  <Col><Button className="text-nowrap" variant="custom-red" disabled={session.cache.inProgress || !session.company} onClick={() => {session.updateContext('FORCE_CACHE', true)}}>{session.cache.buttonText}</Button></Col>
                </Row>
                <Row className="mt-2">
                  <Select
                    styles={{container: provided => ({...provided, width: `${session.companies ? (session.companies.map((c) => {return c.name}).reduce((p, c) => p.length > c.length ? p : c, '').length) : 10}em`})}}
                    options={session.company?.locations}
                    value={locationSelectedOption}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.name}
                    onChange={setLocationSelectedOption}
                    placeholder={session.companies ? companySelectedOption ? session.company?.locations ? "Select a location..." : "No locations exist..." : "Select a company..." : "Loading..."}
                    isClearable={true}
                    isDisabled={companySelectedOption === undefined || session.cache.inProgress || !session.company?.locations}
                    isLoading={session.companies === undefined || session.cache.inProgress}
                  />
                </Row>
              </Container>
            </NavDropdown>
          </Nav.Item>
          <Nav.Item className="mr-auto"><Nav.Link id="bootstrap-override" href="https://iss-na.com/request-service/">ISS Service</Nav.Link></Nav.Item>
          <Nav.Item className="d-flex">{session.appIsOnline ? <Alert variant='success'>Online</Alert> : <Alert variant='secondary'>Offline</Alert>}</Nav.Item>
          <Nav.Item><Navbar.Text id="signedIn"> Signed in as: <br /><span id="username">{session?.user?.name ? session.user.name : "UNKNOWN"}</span> </Navbar.Text></Nav.Item>
          <Nav.Item ><Nav.Link onClick={() => logout()}><div id="logout-box"><img alt="" src={logoutArrow} width="30"  /></div></Nav.Link></Nav.Item>
        </Navbar.Collapse>
      </Nav>
    </Navbar>
  )
}