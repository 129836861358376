import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';
import AddCalibration from './AddCalibration.js';
import LookupAll from './LookupAll.js';
import AddEquipment from './AddEquipment.js';
import AddPOC from './AddPOC.js';
import ManualLookup from './ManualLookup.js';
import SessionContext from './SessionContext.js';
import JobApproval from './JobApproval.js';
import AddESR from './AddESR.js';
import ESRApproval from './ESRApproval.js';

// This component renders various other components based on the page's actual URL. It's really what enables our app to be an SPA.
class RoleRouting extends React.Component {
    
    static contextType = SessionContext;

    userIs(str) { // helper function simply makes the following a bit easier to read
        return this.context?.user?.spectare?.roles ? this.context.user.spectare.roles.includes(str) : false
    }

    render() {
        return (
        <>
            <Route exact path='/'><LookupAll /></Route>
            <Route exact path='/home'><LookupAll /></Route>
            <Route exact path='/diagnostics/calibrations'><LookupAll /></Route>
            <Route exact path='/diagnostics/manual-lookup'><ManualLookup /></Route>
            <Route exact path='/calibrations/:company_id/:equipment_id' children={<LookupAll />} />
            {this.context?.user?.roles?.includes('admin') || this.userIs("technician") || this.userIs("admin") ? 
                <>
                    <Route exact path='/diagnostics/job-approval'><JobApproval /></Route>
                    <Route exact path='/diagnostics/esr-approval'><ESRApproval /></Route>
                </>
                : <></>
            }    
            {this.userIs("admin") || this.userIs ("technician") ? 
                <>
                    <Route exact path='/diagnostics/calibrations/add-calibration'><AddCalibration /></Route>
                    <Route exact path='/diagnostics/calibrations/add-equipment'><AddEquipment /></Route>
                    <Route exact path='/diagnostics/calibrations/add-esr'><AddESR /></Route>
                    <Route exact path='/diagnostics/calibrations/add-poc'><AddPOC /></Route>
                    <Route exact path='/add-calibrations/:company_id/:equipment_id' children={<AddCalibration />} /> 
                </>
                : <></>
            }
        </>
        );
    }
}
export default withAuth0(RoleRouting);